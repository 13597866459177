import {downgradeComponent, downgradeInjectable} from "@angular/upgrade/static";

// Field: Text Field
import {TextFieldInForm} from "@/angular-blocworx/components/fields/text/text-field-in-form.component";
import {TextFieldInData} from "@/angular-blocworx/components/fields/text/text-field-in-data.component";
import {TextFieldCreateEditRadioButton} from "@/angular-blocworx/components/fields/text/text-field-create-edit-radio-button.component";
import {TextFieldCreateEditPopup} from "@/angular-blocworx/components/fields/text/text-field-create-edit-popup.component";
import {TextFieldServiceBLCXAU} from "@/angular-blocworx/components/fields/text/text.service";
import {MathsFactoryBLCXAU} from "@/angular-blocworx/components/fields/maths/maths.factory";
import { RuleService } from '@/angular-blocworx/components/rule/service/rule.service'
import {ScanStationService} from "@/angular-blocworx/components/scanStation/scan-station-service";
import {ScanStationComponent} from "@/angular-blocworx/components/scanStation/scan-station.component";

angular.module('BlocworxModule')
    .directive('textFieldInForm', downgradeComponent({component: TextFieldInForm}))
    .directive('textFieldInData', downgradeComponent({component: TextFieldInData}))
    .directive('textFieldCreateEditRadioButton', downgradeComponent({component: TextFieldCreateEditRadioButton}))
    .directive('textFieldCreateEditPopup', downgradeComponent({component: TextFieldCreateEditPopup}));

angular.module('BlocworxModule')
    .factory('TextFieldServiceBLCXAU', downgradeInjectable(TextFieldServiceBLCXAU));

angular.module('BlocworxModule')
    .factory('MathsFactoryBLCXAU', downgradeInjectable(MathsFactoryBLCXAU));

// Calendar Resource Planning
import {CalendarResourcePlanningInForm} from "@/angular-blocworx/components/fields/calendar-resource-planning/calendar-resource-planning-in-form.component";
import {CalendarResourcePlanningInData} from "@/angular-blocworx/components/fields/calendar-resource-planning/calendar-resource-planning-in-data.component";
import {CalendarResourcePlanningEditRadioButton} from "@/angular-blocworx/components/fields/calendar-resource-planning/calendar-resource-planning-edit-radio-button.component";
import {CalendarResourcePlanningCreateEditPopup} from "@/angular-blocworx/components/fields/calendar-resource-planning/calendar-resource-planning-create-edit-popup.component";

angular.module('BlocworxModule')
    .directive('calendarResourcePlanningInForm', downgradeComponent({component: CalendarResourcePlanningInForm}))
    .directive('calendarResourcePlanningInData', downgradeComponent({component: CalendarResourcePlanningInData}))
    .directive('calendarResourcePlanningEditRadioButton', downgradeComponent({component: CalendarResourcePlanningEditRadioButton}))
    .directive('calendarResourcePlanningCreateEditPopup', downgradeComponent({component: CalendarResourcePlanningCreateEditPopup}));

import {CalendarResourcePlanningService} from "@/angular-blocworx/components/fields/calendar-resource-planning/calendar-resource-planning.service";

angular.module('BlocworxModule')
    .factory('CalendarResourcePlanningService', downgradeInjectable(CalendarResourcePlanningService));

angular.module('BlocworxModule')
       .service('RuleService', downgradeInjectable(RuleService));

import {ChangeBlocModule} from "@/angular-blocworx/components/BlocSettings/changeBlocModule/change-bloc-module.component";

angular.module('BlocworxModule')
    .directive('changeBlocModule', downgradeComponent({component: ChangeBlocModule}));

import {AutocompleteSearch} from "@/angular-blocworx/components/autocompleteSearch/autocomplete-search.component";

angular.module('BlocworxModule')
    .directive('autocompleteSearch', downgradeComponent({component: AutocompleteSearch}));

import {VersionControl} from "@/angular-blocworx/components/admin/version-control/version-control.component";

angular.module('BlocworxModule')
    .directive('versionControl', downgradeComponent({component: VersionControl}));

// Evervault
import {EvervaultAppSetting} from "@/angular-blocworx/components/admin/app-setting/evervault/evervault-app-setting.component";

angular.module('BlocworxModule')
    .directive('evervaultAppSetting', downgradeComponent({component: EvervaultAppSetting}))

import {EvervaultService} from "@/angular-blocworx/components/admin/app-setting/evervault/evervault.service";

angular.module('ConfigurationModule')
    .factory('EvervaultService', downgradeInjectable(EvervaultService));


// Angular App Setting
import {AppSettingService} from "@/angular-blocworx/components/admin/app-setting/app-setting.service";

angular.module('ConfigurationModule')
    .factory('AppSettingService', downgradeInjectable(AppSettingService));


// Field: Results Box
import {ResultsBoxInForm} from "@/angular-blocworx/components/fields/results-box/results-box-in-form.component";
import {ResultsBoxInData} from "@/angular-blocworx/components/fields/results-box/results-box-in-data.component";
import {ResultsBoxCreateEditRadioButton} from "@/angular-blocworx/components/fields/results-box/results-box-create-edit-radio-button.component";
import {ResultsBoxCreateEditPopup} from "@/angular-blocworx/components/fields/results-box/results-box-create-edit-popup.component";
import {ResultsBoxService} from "@/angular-blocworx/components/fields/results-box/results-box.service";

angular.module('BlocworxModule').directive('resultsBoxInForm', downgradeComponent({component: ResultsBoxInForm}))
    .directive('resultsBoxInData', downgradeComponent({component: ResultsBoxInData}))
    .directive('resultsBoxCreateEditRadioButton', downgradeComponent({component: ResultsBoxCreateEditRadioButton}))
    .directive('resultsBoxCreateEditPopup', downgradeComponent({component: ResultsBoxCreateEditPopup}))
    .factory('ResultsBoxService', downgradeInjectable(ResultsBoxService));

// Grid
import {GridService} from "@/angular-blocworx/components/grid/grid.service";
angular.module('BlocworxModule').factory('GridService', downgradeInjectable(GridService));

angular.module('BlocworxModule').directive('resultsBoxInForm', downgradeComponent({component: ResultsBoxInForm}))

angular.module('BlocworxModule').directive('scanStationComponent', downgradeComponent({component: ScanStationComponent}))
angular.module('BlocworxModule').factory('AngularScanStationService', downgradeInjectable(ScanStationService));

import {ManageAndBuildTemplatesComponent} from "@/angular-blocworx/components/BlocSettings/manage-and-build-templates/manage-and-build-templates.component";
import angular from "angular";
angular.module('BlocworxModule').directive('manageAndBuildTemplates', downgradeComponent({component: ManageAndBuildTemplatesComponent}));

import {DataEditHistory} from "@/angular-blocworx/components/scanStation/data-edit-history/data-edit-history.component";
angular.module('BlocworxModule').directive('somethingElse', downgradeComponent({component: DataEditHistory}));

