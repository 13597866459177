"use strict";

import * as angular from "angular";

angular.module('ManagementModule').config(homeRoutes);

homeRoutes.$inject = ['ConfigurationProvider', '$stateProvider'];

function homeRoutes(ConfigurationProvider, $stateProvider)
{
    $stateProvider.state('data-lookup-edit', {
        parent: 'app',
        url: '/manage-data/data-lookup-edit',
        views: {
            'parent': {
                template: '<lookup main="main" data="data" action="dataManager"></lookup>'
            }
        },
        params: {
            editEntity: false,
            editMode: false,
            usesRole: 'data-manager',
            sidebarHighlighter: 'manage-data'
        }
    });

}