import * as angular from 'angular';
import { Calendar, sliceEvents, createPlugin } from '@fullcalendar/core';

import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';

import "@fullcalendar/common/main.css";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";

import * as $ from "jquery";

import * as moment  from "moment";

"use strict";

angular.module('CalendarModule')
  .controller('CalendarController', CalendarController);

// Injection of each class
CalendarController.$inject = ['CalendarFactory', '$scope'];

// Controller Constructor
function CalendarController(CalendarFactory, $scope) {

  // Creating the view model reference
  let vm = this;
  vm.calendarData = {};

  /**
   * This will be responsible to configure a new calendar
   * and make available to load with correct configuration set.
   * @param calendarEl
   */
  vm._loadCalendar = (calendarEl) => {
    try
    {
      // getting the calendar object
      vm.calendar = new Calendar(calendarEl, {
        plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
        selectable: true,
        initialView: 'dayGridMonth',
        themeSystem: 'bootstrap',
        events: vm.calendarData,
        displayEventTime: true,
        headerToolbar: {
          left: 'dayGridMonth,dayGridWeek',
          center: 'title',
          right: 'prevYear,prev,next,nextYear'
        },
        eventTimeFormat: {
          hour: '2-digit',
          minute: '2-digit',
          meridiem: false
        },
        views: {
          dayGridWeek: {
            dayMaxEventRows: 20,
            dayHeaderFormat: function(date){
              return moment(date.date).format('DD/MM');
            },

          },
          monthGrid: {
            eventLimit: false
          }
        },
        eventClick: async (info) => await vm.calendarDateClick(info),
        dateClick: async (info) => await vm.calendarDateClick(info),
        eventContent: (arg) => vm.calendarEventContent(arg),

      });

      // Rendering the calendar object after mounting it
      vm.calendar.render();

    } catch (e) {
      console.log(e);
    }
  }

  /**
   * This will change the html to be printed
   *
   * @param info
   */
  vm.calendarEventContent = (eventInfo) => {

    let p = document.createElement('p')
    // p.innerHTML = `<b>${eventInfo.timeText}</b><i>${eventInfo.event.title}</i>`;
    p.innerHTML = `${eventInfo.event.title}`;
    let arrayOfDomNodes = [ p ]
    return { domNodes: arrayOfDomNodes }

  }

  /**
   * This will trigger when an day is selected.
   *
   * @param info
   */
  vm.calendarDateClick = async (info) => {
    try
    {
      let day = '';

      // Building date
      if (info.dateStr !== undefined){
        day = info.dateStr;
      } else {
        let dateSplit = info.dateStr.split(/[T,+]/);
        day = dateSplit[0];
      }

      // building the data filtering object
      let dataFilteringData = {
        'stationID': vm.scanStation.id,
        'filterDataToQuery' : {},
        'startDate' : null,
        'endDate' : null

      }

      // if we're using a primary calendar field setting then we do a data look up by that value
      if(vm.scanStation.scanStationObj.calendarPrimaryFieldFormat != null && vm.scanStation.fieldToCheckDateBy != null) {
        let d = new Date(day);

        vm.scanStation.scanStationObj.dataToAdd[vm.scanStation.fieldToCheckDateBy] = moment(d).format(vm.scanStation.scanStationObj.calendarPrimaryFieldFormat);

        dataFilteringData.filterDataToQuery[vm.scanStation.fieldToCheckDateBy] = [vm.scanStation.scanStationObj.dataToAdd[vm.scanStation.fieldToCheckDateBy]];

        await vm.scanStation.updateDateFromDataToAddField(vm.scanStation.fieldToCheckDateBy);
        await vm.scanStation.getDataForDataFiltering('with-filter',dataFilteringData);
        $scope.$apply();

      } else {

        // if we dont have the primary calendar setting turned on we simply look up by the dates
        vm.scanStation.fromDate = `${day}`;
        vm.scanStation.toDate = `${day}`;
        await vm.scanStation.getData(vm.scanStation.id);
        $scope.$apply()

      }

    } catch (e) {
      console.log(e);
    }

  }

  /**
   * Main constructor of this controller class.
   */
  vm.$onInit = async () => {
    try
    {
      // loading calendar data
      $scope.scanStation = vm.scanStation;
      vm.calendarData = await vm.getCalendarData();
      vm._loadCalendar(document.getElementById('full-calendar'));

    } catch (e) {
      console.log(e);
    }

  }

  /**
   * This will take the current scan station object and using the
   * CalendarFactory it will be loading the data for this calendar
   * that will be returned an object with data for our calendar.
   *
   */
  vm.getCalendarData = async function () {
    try
    {

      let data = await CalendarFactory.getCalendarData(vm.scanStation);

      return CalendarFactory.buildingCalendarEvents(data.data, vm.scanStation.scanStationObj.calendarPrimaryFieldFormat);

    } catch (e) {
      console.log(e);
    }
  };

}


export default CalendarController;
