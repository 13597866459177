import * as angular from 'angular';
import '../legacy/tinymce-imports'
import 'angular-material';
import 'angular-route';
import 'angular-ui-router/release/angular-ui-router'
import 'satellizer';
import 'angular-ui-tinymce'
import 'angularjs-datetime-picker';
import 'angular-ui-bootstrap';
import 'angular-animate'
import 'angular-sanitize';
import 'file-saver';
import 'chart.js/dist/Chart.bundle.min';

'use strict';

const librariesModules = [
    'ngRoute',
    'ngMaterial',
    'ui.router',
    'satellizer', // TODO: check this one
    'ui.tinymce',
    'angularjs-datetime-picker',
    'ngAnimate',
    'ui.bootstrap',
    'ngSanitize',
]

const legacyModules = [
    'MainCtrl',
    'AuthCtrl',
    'UserCtrl',
    'ValidatorsModule',
    'LanguagesModule',
    'UserService',
    'AuthService',
    'EnterDataCtrl',
    'DateService',
    'EnterDataService',
    'ManagerService',
    'CustomerCtrl',
    'ManagerCtrl',
    'scanStationCtrl',
    'scanStationService',
    'Module1UserManagementService',
    'complianceCtrl',
    'complianceService',
    'ConfigurationModule',
    'splitFilterModule',
    'uniqueFilterModule',
    'confirmedClickModule',
    'fileModelModule',
    'focusMeModule',
    'formceptionModule',
    'nextFocusModule',
    'onModelChangeModule',
    'upperCaseModule',

];

const componentsModules = [
    'BlocworxModule',
    'StandardsModule',
    'ExceptionsModule',
    'ExportModule',
    'PermissionModule',
    'SoundModule',
    'ConfigurationModule',
    'MomentModule',
    'PreventTemplateCacheModule',
    'FieldModule',
    'HelpModeModule',
    'DragDropModule',
    'FilterModule',
    'GridModule',
    'ScanStationModule',
    'SuperUserModule',
    'CalendarModule',
    'IconModule',
    'PageModule',
    'SidebarPagesModule',
    'UrlModule',
    'PageBarModule',
    'BreadcrumbModule',
    'BackButtonModule',
    'TemplateModule',
    'DownloadModule',
    'DatetimeModule',
    'RolesAndPermissionsModule',
    'ModuleModule',
    'UsersModule',
    'ReportsModule',
    'DetectInactivityModule',
    'ManageInactivityModule',
    'ResetForgotPasswordModule',
    'ManagementModule',
    'LoginAttemptsModule',
    'AdvancedReportsModule',
    'ButtonsModule',
    'TwoFactorAuthenticationModule',
    'SignInWithGoogleModule',
    'SocketsModule',
    'SchedulersDataModule'
]

const requiredModulesToLoad = librariesModules.concat(legacyModules).concat(componentsModules);

angular.module('app', requiredModulesToLoad)
    .config(routesRulesToLoad);

routesRulesToLoad.$inject = ['ConfigurationProvider', '$stateProvider', '$urlRouterProvider', '$authProvider', '$compileProvider', '$locationProvider', '$httpProvider'];

function routesRulesToLoad(ConfigurationProvider, $stateProvider, $urlRouterProvider, $authProvider, $compileProvider, $locationProvider, $httpProvider) {

    let apiUrl = ConfigurationProvider.getApiUrl();
    $httpProvider.useApplyAsync(true);

    // TODO: check why we can't disable the debug
    $compileProvider.debugInfoEnabled(true);
    // $compileProvider.debugInfoEnabled(false);

    // TODO: check why we need directives enabled for the directive datetimePicker
    // $compileProvider.commentDirectivesEnabled(false);

    $compileProvider.cssClassDirectivesEnabled(false);
    $locationProvider.html5Mode(true);

    // TODO: We need to build the 404 page
    $urlRouterProvider.otherwise('404');

    $urlRouterProvider.rule(function ($injector, $location) {
        let path = $location.path();
        let hasTrailingSlash = path[path.length - 1] === '/';

        if (hasTrailingSlash) {
            let result =path.substr(0, path.length - 1);
            return result;
        }

    });

    // route which the JWT should be retrieved from
    $authProvider.loginUrl = apiUrl + 'authenticate';

}
