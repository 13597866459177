import * as angular from 'angular';

'use strict';

angular.module('SchedulersDataModule')
    .service('SchedulersDataService', SchedulersDataService)
    .config(SchedulersDataConfiguration);

/**
 * Object that will set aHrefSanitizationWhitelist for requests
 * in this factory.
 *
 * @param $compileProvider
 * @constructor
 */
function SchedulersDataConfiguration($compileProvider: any) {
    $compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|ftp|mailto|tel|file|blob):/);
}

SchedulersDataService.$inject = ['$http', 'Configuration'];

function SchedulersDataService($http, Configuration): void {
    let service = this;

    let apiUrl = Configuration.getApiUrl();

    /**
     * Request the automated reports data from backend
     * @returns automated reports schedule data
     */
    service.getAutomatedReportsScheduleDataService = async function () {

        try {
            let apiBase = apiUrl + 'get-automated-reports-schedule-data';

            let response = await $http.get(apiBase)

            return response.data

        } catch (exception) {
            console.error(exception)
        }
    }

    /**
     * Request the mail alerts data from backend
     * @returns mail alert schedule data
     */
    service.getMailAlertsScheduleDataService = async function () {
        try {
            let apiBase = apiUrl + 'get-mail-alert-schedule-data';

            let response = await $http.get(apiBase)

            return response.data
        } catch (exception) {
            console.error(exception)
        }
    }

    /**
     * Request the gateway data from backend
     * @returns gateway schedule data
     */
    service.getGatewayScheduleDataService = async function () {
        try {
            let apiBase = apiUrl + 'get-gateway-schedule-data';

            let response = await $http.get(apiBase)

            return response.data
        } catch (exception) {
            console.error(exception)
        }
    }
    
    /**
     * Request the bloc scheduler from backend
     * @returns bloc scheduler data
     */
    service.getBlocSchedulerDataService = async function () {
        try {
            let apiBase = apiUrl + 'get-bloc-scheduler-data';

            let response = await $http.get(apiBase)

            return response.data
        } catch (exception) {
            console.error(exception)
        }
    }
    
    /**
     * Request the dynamic data upgrade from backend
     * @returns dynamic data upgrade data
     */
    service.getDynamicDataUpgradeScheduleDataService = async function () {
        try {
            let apiBase = apiUrl + 'get-dynamic-data-upgrade-schedule-data';

            let response = await $http.get(apiBase)

            return response.data
        } catch (exception) {
            console.error(exception)
        }
    }
    
    /**
     * Request the Automatic data deletion from backend
     * @returns auto delete data
     */
    service.getAutoDeleteScheduleDataService = async function () {
        try {
            let apiBase = apiUrl + 'get-auto-delete-schedule-data';

            let response = await $http.get(apiBase)

            return response.data
        } catch (exception) {
            console.error(exception)
        }
    }

    return service;
}