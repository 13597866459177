import "./bootstrap.min.css";
import "./font-awesome/css/all.css";
import "./style2021.css";
import "./979px-1340px.css";
import "./max-979px2020.css";
import "./angularjs-datetime-picker.css";
import "./angular-date-picker.css";
import "./compliance2020.css";
import "./supportScreen2020.css";
import "./blocworx-customer-targeted.css";
import "./moment-calendar.css";
import "./blocworx-customer.css";
import "./Glyphter.css";
import "./components.css";