import * as angular from 'angular';

"use strict";

angular.module('BlocworxModule')
    .service('AppSettings', AppSettingsService);

AppSettingsService.$inject = ['Configuration', '$http', '$window', 'LanguagesFactory', 'MessageService'];

/**
 * Service constructor.
 * @constructor
 */
function AppSettingsService(Configuration, $http, $window, LanguagesFactory, MessageService) {

    let AppSettingsService = this;
    let apiUrl = Configuration.getApiUrl();

    /**
     * Constructor method.
     */
    AppSettingsService.$onInit = () => {
        try
        {
            AppSettingsService.settings = undefined;
            AppSettingsService.logo = undefined;

        } catch (exception) {
            console.log(exception);
        }
    }

    /**
     * This function will be responsible to ask the PHP backend side
     * to check if exist a file.
     *
     * @param path
     */
    AppSettingsService.existLogo = async (path) => {

        let apiBase = apiUrl + 'exist-file';
        let config = {
            params: {
                path : path
            }
        };
        let exist = await $http.get(apiBase, config);

        return exist.data;
    }


    /**
     * This function will be responsible to ask the PHP backend side
     * to check if exist a file.
     *
     * @param path
     */
    AppSettingsService.loadLogo = async (path, subDomain, reload = false) => {


        let randomValue = Configuration.getRandomValue();

        if(AppSettingsService.logo == undefined || reload)
        {
            // setting default if we cant find something
            let defaultLogo = `/images/customer-logos/default-logo-instruction.png?${randomValue}`;
            AppSettingsService.logo = defaultLogo;

            if(await AppSettingsService.existLogo(path)){
                AppSettingsService.logo = `/api/customer-logos/${subDomain}.png?${randomValue}`;
            }
        }

        return AppSettingsService.logo;

    }

    /**
     * This will be getting the app settings from the database level.
     */
    AppSettingsService.getAppSettings = async () => {

        let apiBase = apiUrl + 'get-app-settings';
        let cartolyticsCustomerID = $window.localStorage.getItem('cartolyticsCustomerID');
        let config = {
            cartolyticsCustomerID : cartolyticsCustomerID
        };
        let settingsBackEndData = await $http.get(apiBase, config);

        if(settingsBackEndData != undefined && settingsBackEndData.data != undefined
            && settingsBackEndData.data.data != undefined){
            return settingsBackEndData.data.data;
        }

    }

    /**
     * This will load all app settings.
     */
    AppSettingsService.load = async () => {
        try
        {
            let settingsBackEndData = await AppSettingsService.getAppSettings();

            if(settingsBackEndData) {
                AppSettingsService.settings = settingsBackEndData;

                AppSettingsService.header = {
                    logo : AppSettingsService.getSettingValue('main_logo'),
                    background : AppSettingsService.getSettingValue('header_background')
                }

                AppSettingsService.sidebar = {
                    logo :  AppSettingsService.getSettingValue('sidebar_logo'),
                    blocworx : AppSettingsService.getSettingValue('blocworx_logo'),
                }

                AppSettingsService.login = {
                    message :  AppSettingsService.getSettingValue('login_message')
                }

                let selectedLanguageCode = AppSettingsService.getSettingValue('language');
                AppSettingsService.language = {
                    selected : selectedLanguageCode,
                    name : LanguagesFactory.getLanguageByCode(selectedLanguageCode.data)
                }

                let defaultMailFooter = 'This mail was sent from Blocworx. Please visit https://www.blocworx.com for more information about this product.';
                AppSettingsService.mail = {
                    footer :  AppSettingsService.getSettingValue('mail_footer'),
                    'default_mail_footer' :  defaultMailFooter
                }

                AppSettingsService.report = {
                    footer :  AppSettingsService.getSettingValue('report_footer'),
                    'default_mail_footer' :  defaultMailFooter
                }
            }

        } catch (exception) {
            console.log(exception);
        }
    }

    /**
     * This will return the setting from the table
     * app_settings.
     *
     * @param key
     */
    AppSettingsService.getSetting = (key) => {
        try
        {
            let settingToReturn = false;
            let settings = AppSettingsService.settings;

            // If we cant find any new setting, must return false
            if(settings == undefined){
                return settingToReturn;
            }

            // trying to get the settings from the settings variable that was loaded on load() function
            for(let index = 0; index < settings.length; index++ )
            {
                if(settings[index].setting_key != undefined && settings[index].setting_key == key){
                    settingToReturn = settings[index];
                }
            }

            return settingToReturn;

        } catch (exception) {
            console.log(exception);
        }
    }

    /**
     * This will return the value set for a property on
     * app_settings.
     *
     * @param key
     */
    AppSettingsService.getSettingValue = (key, defaultValue = true) => {
        try
        {
            let valueToReturn : { data : any; key : any; enabled : any } = { data : false, key : false, enabled : false };
            let settingValue = AppSettingsService.getSetting(key);

            // If we cant find any new setting, must return false
            if(settingValue !== undefined && settingValue !== false){
                valueToReturn = {
                    enabled : Boolean(settingValue.enabled),
                    data : settingValue.setting_value,
                    key : settingValue.setting_key
                }
            }

            return valueToReturn;

        } catch (exception) {
            console.log(exception);
        }
    }

    /**
     * This will be updating the settings on app_settings
     * @param key
     * @param value
     */
    AppSettingsService.updateAppSettings = async (key, value) => {
        try
        {
            let apiBase = apiUrl + 'set-app-settings';

            let config = {
                key: key,
                value: value,
            };

            let updatedAppSettings = await $http.post(apiBase, config);
            await AppSettingsService.load();

        } catch (exception) {
            console.log(exception);
        }
    }

    /**
     * Method that will remove a key from table app_settings
     * @param key
     */
    AppSettingsService.removeAppSettings = async (key) => {
        try
        {
            let apiBase = apiUrl + 'remove-app-settings';

            let config = {
                key: key,
            };
            let updatedAppSettings = await $http.delete(apiBase, config);

        } catch (exception) {
            console.log(exception);
        }
    }

    /**
     * This will get a value or a default value passed.
     * @param value
     * @param defaultValue
     */
    AppSettingsService.getValueOrDefault = (value, defaultValue) => {

        let returnedValue = defaultValue;

        // this will be avoiding the null values or undefined ones
        if(value != null){
            returnedValue = value
        }

        return returnedValue
    }

    /**
     * This will be updating the settings on app_settings
     * @param key
     * @param value
     */
    AppSettingsService.updateEnabledDisabledAppSettings = async (key, settingValue, enabled) => {
        try
        {
            let apiBase = apiUrl + 'update-enabled-disabled-app-settings';

            let config = {
                key: key,
                settingValue: settingValue,
                enabled: enabled,
            };

            let updatedAppSettings = await $http.post(apiBase, config);

            // this will reload if was possible to get the data inserted on the database
            if(updatedAppSettings.data != undefined && updatedAppSettings.data != false){
                await AppSettingsService.load();
            }

            MessageService.addSuccessMessage('Enable/Disable Field Successful');

        } catch (exception) {
            console.log(exception);
        }
    }

    // this will make sure that will be the constructor class like a controller one
    AppSettingsService.$onInit();

    return AppSettingsService;


}