import * as angular from 'angular';

"use strict";

angular.module('SchedulersDataModule')
    .controller('SchedulersDataController', SchedulersDataController);

// Injection of each class
SchedulersDataController.$inject = ['SchedulersDataService', '$scope', 'ScopeAndLocal'];

// Controller Constructor

function SchedulersDataController(SchedulersDataService, $scope, ScopeAndLocal,) {
    let vm = this;
    
    vm.$onInit = function () {
        try {
            ScopeAndLocal.startVariable(vm, $scope, 'main');

            vm.getAutomatedReportsScheduleData();
            vm.getMailAlertsScheduleData();
            vm.getGatewayScheduleData();
            vm.getBlocSchedulerData();
            vm.getDynamicDataUpgradeScheduleData();
            vm.getAutoDeleteScheduleData();

        } catch (error) {
            console.log(error);
        }
    }

    /**
     * Get Data for Automated Reports Schedule
     */
    vm.getAutomatedReportsScheduleData = async function () {
        vm.automatedReportsScheduleData = await SchedulersDataService.getAutomatedReportsScheduleDataService();
        $scope.$apply();
    }
    
    /**
     * Get Data for Mail Alerts Schedule
     */
    vm.getMailAlertsScheduleData = async function () {
        vm.mailAlertsScheduleData = await SchedulersDataService.getMailAlertsScheduleDataService();
        $scope.$apply();
    }
    
    /**
     * Get Data for Gateway Schedule
     */
    vm.getGatewayScheduleData = async function () {
        vm.gatewayScheduleData = await SchedulersDataService.getGatewayScheduleDataService();
        $scope.$apply();
    }
    
    /**
     * Get Data for Bloc Scheduler
     */
    vm.getBlocSchedulerData = async function () {
        vm.blocSchedulerData = await SchedulersDataService.getBlocSchedulerDataService();
        $scope.$apply();
    }
    
    /**
     * Get Data for Dynamic Data Upgrade Scheduler
     */
    vm.getDynamicDataUpgradeScheduleData = async function () {
        vm.dynamicDataUpgradeScheduleData = await SchedulersDataService.getDynamicDataUpgradeScheduleDataService();
        $scope.$apply();
    }
    
    /**
     * Get Data for Automatic Data Deletion Scheduler
     */
    vm.getAutoDeleteScheduleData = async function () {
        vm.autoDeleteScheduleData = await SchedulersDataService.getAutoDeleteScheduleDataService();
        $scope.$apply();
    }
}

export default SchedulersDataController;
