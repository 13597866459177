import * as angular from 'angular';

"use strict";

angular.module('ScanStationModule')
       .controller('ScanStationHeaderController', ScanStationHeaderController);

// Injection of each class
ScanStationHeaderController.$inject = ['Configuration', '$scope', 'Permission'];

// Controller Constructor
function ScanStationHeaderController(Configuration, $scope, Permission) {

    let vm = this;
    vm.scanStationController = null;
    vm.dataController = null;

    let version = vm.version = Configuration.getVersion();
    let random = Configuration.getRandomValue();
    
    vm.headerUrl = 'components/scanstation/template/header-scan-station.html?v=' + version + 'rand=' + random;

    vm.init = function (scanStationController, data) {


        let scanStationObject = scanStationController.scanStationObj;

        vm.scanStationController = scanStationController;
        vm.dataController = data;
        vm.stationName = scanStationObject.stationName;

        vm.showMoreSingleDataBox = scanStationController.showMoreSingleDataBox;

    }

    /**
     * This will rollback the data from the back actions from
     * viewing and editing actions.
     */
    vm.rollbackAction = async function () {
        try
        {
            await vm.scanStationController.resetData();
            await vm.scanStationController.updateAutoGenerateFields();

            $scope.$apply();

        } catch (e) {
            console.log(e);
        }

    }

    /**
     * Close action for the main close button on the
     * Scan Station view page.
     */
    vm.closeClick = function () {
        try
        {
            vm.scanStationController.scanStationObj.showForm = false;

        } catch (e) {
            console.log(e);
        }

    }

    /**
     * This is the implementation of the edit field
     * on the show more -> View mode, button: Edit Data
     */
    vm.editFieldClick = async function () {
        try
        {
            vm.dataController.showForm = true;
            vm.scanStationController.editDataField = !vm.scanStationController.editDataField;

            let row = vm.scanStationController.singleEntry;
            let rowKey = vm.scanStationController.rowKey;

            vm.scanStationController.setFieldsEditEntry(row, rowKey);
            vm.scanStationController.showMoreSingleDataBox = false;
            vm.scanStationController.scanStationObj.isInEditMode = true;
            vm.scanStationController.scanStationObj.isInViewMode = false;
            await vm.scanStationController.editCheckFields(row);

            if (vm.scanStationController.scanStationObj.stationDetails.hide_form && vm.scanStationController.scanStationObj.stationDetails.hide_form == 1) {
                vm.scanStationController.scanStationObj.stationDetails.hide_form = 0
            }
            $scope.$apply();

        } catch (e) {
            console.log(e);
        }

    }

    /**
     * This will disable the edit mode, and will reset the data and
     * updateAutoGenerateFields.
     */
    vm.cancelEditClick = async function () {
        try
        {
            vm.scanStationController.scanStationObj.isInEditMode = false;

            // if the form is hidden by default then when we are finished viewing more it should make sure the form is hidden again
            // form should only show if we clicked the Add New button
            if(vm.scanStationController.scanStationObj.stationDetails.hide_form==1) {
                await vm.closeClick();
            }

            await vm.rollbackAction();
            $scope.$apply();
        } catch (e) {
            console.log(e);
        }

    }

    /**
     * This will disable the viewing mode, and will reset the data and
     * updateAutoGenerateFields.
     */
    vm.cancelViewingClick = async function () {
        try
        {
            vm.scanStationController.showMoreSingleDataBox = false;

            // if the form is hidden by default then when we are finished viewing more it should make sure the form is hidden again
            // form should only show if we clicked the Add New button
            if(vm.scanStationController.scanStationObj.stationDetails.hide_form==1) {
                await vm.closeClick();
            }

            await vm.rollbackAction();
            $scope.$apply();
        } catch (e) {
            console.log(e);
        }

    }

    /**
     * Close button validation, this should show only
     * if we aren't on edit or showing views.
     *
     * @returns {boolean}
     */
    vm.closeValidation = function () {
        try
        {
            if (vm.scanStationController.scanStationObj.isInEditMode != true && vm.scanStationController.showMoreSingleDataBox != true) {
                return true;
            }

            return false;

        } catch (e) {
            console.log(e);
        }

    }

    /**
     * This will check if exist the jobData inside of a dataController that will
     * need to be passed as an argument.
     *
     * @param dataController
     */
    vm.checkExistJobDataInDataController = function (dataController) {
        try
        {
            return (typeof dataController.jobData !== 'undefined' && dataController.jobData !== null) ? true : false;

        } catch (e) {
            console.log(e);
        }

    }

    /**
     * RMA number validation.
     *
     * @returns {boolean}
     */
    vm.rmaNumberValidation = function () {
        try
        {
            if (vm.checkExistJobDataInDataController(vm.dataController) && vm.dataController.jobData.rma_number != null) {
                return true;
            }

            return false;

        } catch (e) {
            console.log(e);
        }

    }

    /**
     * Serial number validation.
     *
     * @returns {boolean}
     */
    vm.serialNumberValidation = function () {
        try
        {
            if (vm.checkExistJobDataInDataController(vm.dataController) &&  vm.dataController.jobData.serial_number != null) {
                return true;
            }

            return false;

        } catch (e) {
            console.log(e);
        }

    }

    /**
     * Method that will check if its the first stage, the default stage.
     *
     * @returns {boolean} false for not viewing or editing, true for the default stage.
     */
    vm.isDefaultMode = function () {
        try
        {
            if (!vm.scanStationController.scanStationObj.isInEditMode && !vm.scanStationController.showMoreSingleDataBox) {
                return true;
            }

            return false;

        } catch (e) {
            console.log(e);
        }

    }

    /**
     * Method that will check if it is in Viewing Mode.
     *
     * @returns {boolean} true for viewing mode, false for in edit mode
     */
    vm.isViewMode = function () {
        try
        {
            if (!vm.scanStationController.scanStationObj.isInEditMode && vm.scanStationController.showMoreSingleDataBox == true) {
                return true;
            }

            return false;

        } catch (e) {
            console.log(e);
        }

    }

    /**
     * Method that will check if it is in Edit Mode.
     *
     * @returns {boolean} true for in edit mode, false for viewing mode
     */
    vm.isEditMode = function () {
        try
        {
            if (vm.scanStationController.scanStationObj.isInEditMode && vm.scanStationController.showMoreSingleDataBox == false) {
                return true;
            }

            return false;

        } catch (e) {
            console.log(e);
        }

    }

    /**
     * Method that will check if it is in Edit Mode.
     *
     * @returns {boolean} true for in edit mode, false for viewing mode
     */
    vm.canEdit = function (main,scanStation) {
        try
        {
            return Permission.canEditScanStation(main, scanStation);

        } catch (e) {
            console.log(e);
        }

    }

}
