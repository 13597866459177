import * as angular from 'angular';
import TemplateController from "./controllers/template.controller";

"use strict";

let templateDocumentManagerComponent =  {
    templateUrl: 'components/template/template/template-document-manager.html',
    controller: TemplateController,
    controllerAs: 't',
    bindings: {
        scanStation: '=',
    }
}

angular.module('TemplateModule')
       .component('templateDocumentManager', templateDocumentManagerComponent);

let templateDocumentSelectorComponent =  {
    templateUrl: 'components/template/template/template-document-selector.html',
    controller: TemplateController,
    controllerAs: 't',
    bindings: {
        scanStation: '=',
        fc: '='
    }
}

angular.module('TemplateModule')
    .component('templateDocumentSelector', templateDocumentSelectorComponent);
