import * as angular from 'angular';

'use strict';

angular.module('BlocworxModule')
       .service('BlocworxTranslationService', BlocworxTranslationService);

BlocworxTranslationService.$inject = ['LanguagesFactory'];

/**
 * This will be responsible to get things related to a translation
 * this is the main service, and we should use it as base for
 * all translation jobs.
 */
export default function BlocworxTranslationService(LanguagesFactory) {

    const service = this;
    service.languages = LanguagesFactory;

    /**
     * This will return a translated result. This will be checking for default value
     * name and if we do not have name, goes to slug, so if you see a slug on the select option
     * means that is missing the name of it.
     *
     * @param translationObject
     */
    service.getTranslation = (translationObject) => {
        let returnValue = false;
        let defaultValue = false;
        try
        {
            // loading the default value
            if(translationObject.name != undefined){
                defaultValue = translationObject.name;
            }

            // loading the default value for slug if we cant find the name
            if(defaultValue == false && translationObject.slug != undefined){
                defaultValue = translationObject.slug;
            }

            // trying to load the return value by using the translation_word
            if(translationObject.translation_word != undefined) {
                returnValue = service.languages.translatedWords[translationObject.translation_word];
            }

            // check if the value is null, if it is, we must get the default value
            return returnValue == null || returnValue == false ? defaultValue : returnValue;

        } catch (e) {
            console.log(e)
        }

    }

    return service;

}
