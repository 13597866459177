import * as angular from 'angular';
import * as $ from "jquery";

"use strict";

angular.module('RolesAndPermissionsModule')
       .controller('RolesAndPermissionsController', RolesAndPermissionsController);

RolesAndPermissionsController.$inject = ['$scope', 'RolesAndPermissionsFactory'];

// Controller Constructor
function RolesAndPermissionsController($scope, RolesAndPermissionsFactory) {
    // Creating the view model reference
    let vm = this;

    vm.data = '';
    vm.main = '';
    vm.requires2FA = 0;

    vm.$onInit = function () {
        try {
            $scope.data = vm.data;
            $scope.main = vm.main;


        } catch (e) {
            console.log(e);
        }
    }

    // method to get just the permissions on their own that are in Blocworx

    vm.getAllPermissions = async function () {
        try {
            let data = await RolesAndPermissionsFactory.getAllPermissions();
            vm.permissions = data.data.data;

        } catch (e) {
            console.log(e);
        }
    }

    // method to get the roles created by this customer and the permissions the role has

    vm.getRolesAndPermissions = async function () {
        try {
            let data = await RolesAndPermissionsFactory.getRolesAndPermissions();
            vm.rolesAndPermissions = data.data.data;

        } catch (e) {
            console.log(e);
        }
    }

    /*
    Method to create new role
     */
    vm.createNewRole = async function () {
        await RolesAndPermissionsFactory.createNewRole(vm.newRoleName, vm.newRoleSelectedPermissions, vm.requires2FA, vm.canEditOwnAccount);
        await vm.getRolesAndPermissions();
        vm.newRoleName = '';
        vm.newRoleSelectedPermissions = {};
        $scope.$apply();
    }

    /**
     * This will be responsible to expand and contract the manage roles and permission
     * boxes, so this function will be making sure that:
     * 1 - will expand or contract the current box
     * 2 - will be contract all the other boxes, so only one can be opened
     * 3 - it will be revert the current status of the clicked box
     *
     * @param role
     */
    vm.expandBox = (role) => {

        vm.selectedRolePermissions=[];
        let currentStatus = vm.showAccordianDrop[role.id];

        // update the current editing role name
        vm.editedRoleName = role.name;

        // this will be responsible to make all the other ones closed, so we can guarantee that only one will be open
        angular.forEach(vm.showAccordianDrop, (show, index) => vm.showAccordianDrop[index] = false);

        // this will make sure to expand or close the current element
        vm.showAccordianDrop[role.id]= !currentStatus

    }

    /*
       Method to edit the role name and its permissions
       @param roleId
    */
    vm.updateRole = async function (roleId, permissions, requires2FA, canEditOwnAccount) {
        angular.forEach(permissions, function (permission, index) {
            if (permission == 0) {
                delete permissions[index];
            }
        });
        await RolesAndPermissionsFactory.updateRole(roleId, vm.editedRoleName, permissions, requires2FA, canEditOwnAccount);
        await vm.getRolesAndPermissions();
        vm.showAccordianDrop[roleId] = false;
        $scope.$apply();
    }

    /*
    Method to delete the role
    @param roleId
    */
    vm.deleteRole = async function (roleId) {
        await RolesAndPermissionsFactory.deleteRole(roleId);
        await vm.getRolesAndPermissions();
        $scope.$apply();
    }
	
	vm.removePermissionFromRole = async function (rolePermissions, permissionSlug) {
      let index = rolePermissions.indexOf(permissionSlug);
      delete rolePermissions[index];
    }

}

export default RolesAndPermissionsController;