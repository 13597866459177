
// This is the main file that you should import all the routes
import 'components/blocworx/routes/home.route'
import "components/blocworx/components/admin/routes/admin.route";
import "components/blocworx/components/lookup/routes/lookup.route.ts";
import "components/blocworx/components/user/routes/user.route";
import "components/management/routes/management.route";
import "components/sign-in-with-google/routes/sign-in-with-google.route";

// those are the previous legacy routes, this must die with time, so each task that you do,
// move one of them
import './legacyRoutes'