import * as angular from 'angular';


"use strict";

angular
    .module('BlocworxModule')
    .factory('ScanStationHelperFactory', ScanStationHelperFactory);


ScanStationHelperFactory.$inject = ['fieldService', 'Configuration', '$http'];


/**
 * Object that will create a Plain Data Factory, all methods of factory
 * will be available from outside.
 */
function ScanStationHelperFactory(fieldService, Configuration, $http) {
    let factory = this;

    /*
 * Create an array of hours in a day
 * @returns {Array}
 */
    factory.createHourArray = function() {
        let hours = [];
        for (let i = 0; i < 24; i++) {
            if (i < 10) {
                hours.push({"value": i, "text": `0${i}:00`});
            } else {
                hours.push({"value": i, "text": `${i}:00`});
            }
        }
        return hours;
    }

    /*
    * Create an array of days in a month
    * @returns {Array}
    */
    factory.createDayArray = function() {
        let days = [];
        for (let i = 1; i <= 31; i++) {
            if (i < 10) {
                days.push({"value": `0${i}`, "text": `0${i}`});
            } else {
                days.push({"value": i, "text": `${i}`});
            }
        }
        days.push({"value": "last", "text": "Last day of month"});
        return days;
    }


    return factory;
}

