import * as angular from 'angular';

"use strict";

angular.module('PageModule')
       .factory('PageFactory', PageFactory)
       .config(PageConfiguration);

/**
 * Object that will set aHrefSanitizationWhitelist for requests
 * in this factory.
 *
 * @param $compileProvider
 * @constructor
 */
function PageConfiguration($compileProvider) {
    $compileProvider.aHrefSanitizationWhitelist(
        /^\s*(https?|ftp|mailto|tel|file|blob):/);
}

PageFactory.$inject = ['$http', 'Configuration'];

/**
 * Object that will create a Page Factory, all methods of factory
 * will be available from outside.
 *
 * @param $http
 * @param Configuration
 * @constructor
 */
function PageFactory($http, Configuration) {

    let factory = this;

    /**
     * This is the main function to get the grid lines
     * @param newPageData
     * @returns {*}
     */
    factory.addNewPage = function (newPageData) {

        let apiBase = Configuration.getApiUrl() + 'add-new-page';
        let data = {
            'newPageData': newPageData
        };
        return $http.post(apiBase, data);
    }

    factory.deletePage = function (pageId) {
        let apiBase = Configuration.getApiUrl() + 'delete-page';
        let data = {
            'params': {
                'pageId': pageId
            }
        };
        return $http.delete(apiBase, data);
    }

    factory.updatePage = function (pageData) {

        let apiBase = Configuration.getApiUrl() + 'update-page';
        let data = {
            'pageData': pageData
        };
        return $http.post(apiBase, data);
    }

    factory.getPages = function () {

        let apiBase = Configuration.getApiUrl() + 'get-pages';
        let data = {};
        return $http.get(apiBase, data);
    }

    factory.getPageData = function (stateName, pageId, pageSlug) {

        let apiBase = Configuration.getApiUrl() + 'get-page-data';
        let data = {
            'params': {
                stateName: stateName,
                pageId: pageId,
                pageSlug: pageSlug
            }
        };
        return $http.get(apiBase, data);
    }

    /**
     * This will be getting the page sections count by the
     * ID of the page and page slug
     *
     * @param pageId
     * @param pageSlug
     */
    factory.getCountPageSections = async (pageId, pageSlug) => {

        let count = 0;
        let apiBase = Configuration.getApiUrl() + 'get-count-page-sections';
        let data = {
            'params': {
                pageId: pageId,
                pageSlug: pageSlug
            }
        };

        let response = await $http.get(apiBase, data);
        if(response.data != undefined && response.data.count !=undefined){
            count = response.data.count;
        }

        return count;
    }

    factory.getPageSections = function (pageId) {

        let apiBase = Configuration.getApiUrl() + 'get-page-sections';
        let data = {
            'params': {
                pageId: pageId
            }
        };
        return $http.get(apiBase, data);
    }

    factory.addNewPageSection = function addNewPageSection(pageId, sectionName) {
        let apiBase = Configuration.getApiUrl() + 'add-new-page-section';
        let data = {
            pageId: pageId,
            sectionName: sectionName
        };
        return $http.post(apiBase, data);
    }

    factory.deletePageSection = function deletePageSection(sectionID, pageId) {
        let apiBase = Configuration.getApiUrl() + 'delete-page-section';
        let config = {
            params: {
                sectionID: sectionID,
                pageId: pageId
            }
        };
        return $http.delete(apiBase, config);
    }

    factory.updatePageSectionName = function updatePageSectionName(newSectionName, sectionID) {
        let apiBase = Configuration.getApiUrl() + 'update-page-section-name';
        let data = {
            newSectionName: newSectionName,
            sectionID: sectionID
        };
        return $http.post(apiBase, data);
    }

    factory.addSubPage = function addSubPage(subPageName, sectionID, pageId, orderID, newLinkIcon, newRoles, newPageOpenAccess) {
        let apiBase = Configuration.getApiUrl() + 'add-sub-page';
        let data = {
            subPageName: subPageName,
            sectionID: sectionID,
            pageId: pageId,
            orderID: orderID,
            newLinkIcon: newLinkIcon,
            newRoles: newRoles,
            newPageOpenAccess: newPageOpenAccess
        };
        return $http.post(apiBase, data);
    }

    factory.addModule = function addSubPage(moduleName, newModuleCategory, sectionID, pageId, orderID, newLinkIcon) {
        let apiBase = Configuration.getApiUrl() + 'add-module';
        let data = {
            moduleName: moduleName,
			activityStatus: newModuleCategory,
            sectionID: sectionID,
            pageId: pageId,
            orderID: orderID,
            newLinkIcon: newLinkIcon
        };
        return $http.post(apiBase, data);
    }

    factory.addLinkUrl = function addLinkUrl(newLinkName, newLinkUrl, sectionID, pageId, orderID, newLinkIcon, newRoles, newTab) {
        let apiBase = Configuration.getApiUrl() + 'add-link-url';
        let data = {
            newLinkName: newLinkName,
            newLinkUrl: newLinkUrl,
            sectionID: sectionID,
            pageId: pageId,
            orderID: orderID,
            newLinkIcon: newLinkIcon,
            newRoles: newRoles,
            newTab
        };
        return $http.post(apiBase, data);
    }

    factory.deleteLinkUrl = function (id) {
        let apiBase = Configuration.getApiUrl() + 'delete-link-url';
        let data = {
            'params': {
                'id': id
            }
        };
        return $http.delete(apiBase, data);
    }

    factory.addExistingModuleToPage = function addSubPage(moduleId, sectionId, moduleIcon) {
        let apiBase = Configuration.getApiUrl() + 'add-existing-module-to-page';
        let data = {
            moduleId: moduleId,
            sectionId: sectionId,
            moduleIcon: moduleIcon
        };
        return $http.post(apiBase, data);
    }

    factory.getModulePageAndSection = function (moduleId) {

        let apiBase = Configuration.getApiUrl() + 'get-module-page-and-section';
        let data = {
            'params': {
                moduleId: moduleId
            }
        };
        return $http.get(apiBase, data);
    }

    factory.reorderSections = async function reorderSection(direction, sectionId, pageId) {
        let apiBase = Configuration.getApiUrl()  + 'reorder-page-sections';
        let data = {
            direction: direction,
            sectionId: sectionId,
            pageId: pageId
        };
        return $http.post(apiBase, data);
    }

    /**
     * This will increase the page order for a page
     * section.
     *
     * @param sectionId
     * @param pageId
     * @param pageOrder
     */
    factory.increasePageOrder = async (sectionId, pageId) => {

        let pages = [];
        let apiBase = Configuration.getApiUrl()  + 'increase-page-order';
        let data = {
            sectionId: sectionId,
            pageId: pageId,
        };

        let postData = await $http.post(apiBase, data);

        if(postData.data != undefined && postData.data.success != undefined){
            pages = postData.data.pages;
        }

        return pages;
    }

    /**
     * This will decrease the page order for a page
     * section.
     *
     * @param sectionId
     * @param pageId
     * @param pageOrder
     * @param cartolyticsCustomerID
     */
    factory.decreasePageOrder = async (sectionId, pageId) => {

        let pages = [];
        let apiBase = Configuration.getApiUrl()  + 'decrease-page-order';
        let data = {
            sectionId: sectionId,
            pageId: pageId,
        };

        let postData = await $http.post(apiBase, data);

        if(postData.data != undefined && postData.data.success != undefined){
            pages = postData.data.pages;
        }

        return pages;
    }

    return factory;

}
