"use strict";

import * as angular from "angular";

angular.module('BlocworxModule')
       .config(adminRoutes);

adminRoutes.$inject = ['ConfigurationProvider', '$stateProvider'];

function adminRoutes(ConfigurationProvider, $stateProvider)
{
    $stateProvider.state('edit-theme', {
        parent: 'app',
        url: '/edit-theme',
        name: "edit-theme",
        views: {
            'parent': {
                component: 'admin',
                template : '<admin class="content edit-theme" action="edit-theme-options" scan-station="scanStation" bootstrap="bootstrap"></admin>',
            }
        }
    }).state('version-control', {
        parent: 'app',
        url: '/version-control',
        name: "version-control",
        views: {
            'parent': {
                component: 'version-control',
                template : '<version-control [main]="main"></version-control>',
                controller: 'MainController as main'
            }
        }
    });
}