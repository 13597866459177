import * as angular from 'angular';

'use strict';

angular.module('SignInWithGoogleModule')
    .service('SignInWithGoogleService', SignInWithGoogleService)
    .config(SignInWithGoogleConfiguration);

/**
 * Object that will set aHrefSanitizationWhitelist for requests
 * in this factory.
 *
 * @param $compileProvider
 * @constructor
 */
function SignInWithGoogleConfiguration($compileProvider: any) {
    $compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|ftp|mailto|tel|file|blob):/);
}

SignInWithGoogleService.$inject = ['$http', 'Configuration', 'Data', '$window'];

function SignInWithGoogleService($http, Configuration, Data, $window): void {
    let service = this;

    let apiUrl = Configuration.getApiUrl();

    service.setClientId = function (clientId: string) {
        let apiBase = apiUrl + 'insert-or-update-app-setting';
        let body = {
            settingKey: 'client_id',
            settingValue: clientId
        }
        return $http.post(apiBase, body);
    }

    service.setSignInWithGoogle = function (signInWithGoogle: boolean) {
        let apiBase = apiUrl + 'insert-or-update-app-setting';
        let body = {
            settingKey: 'sign_in_with_google',
            settingValue: signInWithGoogle
        }
        return $http.post(apiBase, body);
    }

    service.updateAppSettingInLocalStorage = async function(signInWithGoogle: boolean, clientId: string) {

        let appSettings = await Data.getAppSettings();

        $window.localStorage.setItem('appSettings', JSON.stringify(appSettings.data));

    }

    return service;
}
