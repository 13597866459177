import * as angular from 'angular';

"use strict";

angular.module('TemplateModule')
    .service('TemplateService', TemplateService)
    .config(($compileProvider) => {
        $compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|ftp|mailto|tel|file|blob):/)
    });

TemplateService.$inject = ['$http', 'Configuration'];

/**
 * Service constructor.
 * @constructor
 */
function TemplateService($http, Configuration) {

    let service = this;
    let apiUrl = Configuration.getApiUrl();

    service.getScanStationFieldsSlugs = (scanStationId) => {

        let apiBase = apiUrl + 'get-scan-station-fields-slugs';
        let config = {
            scanStationId: scanStationId
        };

        return $http.post(apiBase, config);
    }

    /**
     * This is responsible for the export of the mockup file,
     * this file will be a base to be possible to import later on.
     *
     * @param scanStationId
     * @param pdfOrDoc
     */
    service.exportScanStationMockup = (scanStationId, pdfOrDoc = 'doc') => {

        let apiBase = apiUrl + 'export-scan-station-mockup';

        let config = {
            responseType: 'blob'
        };

        let body = {
            scanStationId: scanStationId,
            pdfOrDoc: pdfOrDoc
        };

        return $http.post(apiBase, body, config);
    }

    /**
     * This will load all the scan stations templates to be showing on
     * Choose template section in a scan station.
     *
     * @param scanStationId
     */
    service.loadScanStationTemplates = async (scanStationId) => {

        let apiBase = apiUrl + 'load-scan-station-templates';

        let config = {
            params: {
                scanStationId: scanStationId
            }
        };

        // loading the filelist
        let fileList = await $http.get(apiBase, config);

        return fileList.data;
    }

    /**
     * This will be responsible to upload a new template on scan_station_template
     * and on storage folder.
     *
     * @param scanStationId
     * @param templateName
     * @param file
     */
    service.uploadTemplate = async (scanStationId, templateName, file, outputFileName) => {

        let fd = new FormData();
        fd.append('scanStationId', scanStationId);
        fd.append('templateName', templateName);
        fd.append('file', file);
        fd.append('outputFileName', outputFileName);

        let apiBase = apiUrl + 'upload-template';
        let response = await $http.post(apiBase, fd, {transformRequest: angular.identity, headers: {'Content-Type': undefined}});

        // TODO: check the response for the positive an negative cases
        return response.data;
    }

    /**
     * This will be deleting a template by their specific Id.
     * @param templateId
     */
    service.deleteTemplate = async (templateId) => {

        let apiBase = apiUrl + 'delete-template';

        let config = {
            params: {
                templateId: templateId
            }
        };

        let response = await $http.get(apiBase, config);

        return response.data;
    }

}