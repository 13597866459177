import * as angular from 'angular';

"use strict";

angular.module('BlocworxModule')
       .controller('TranslationController', TranslationController);

TranslationController.$inject = ['$scope','ScopeAndLocal'];

// Controller Constructor
function TranslationController($scope, ScopeAndLocal) {

    let vm = this;

    /**
     * Constructor class.
     */
    vm.$onInit = async function () {
        try {

            ScopeAndLocal.startVariable(vm, $scope, 'bootstrap');

        } catch (e) {
            console.log(e);
        }
    }

}

export default TranslationController;