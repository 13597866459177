import * as angular from 'angular';
import * as jQuery from "jquery";
import PageLoadedController from "components/blocworx/controllers/pageLoaded.controller";

'use strict';

angular.module('BlocworxModule')
       .directive('pageLoaded', PageLoaded);

PageLoaded.$inject = [];

/**
 *  Object that will create a Icon Factory, all methods of factor
 *
 * @constructor
 */
function PageLoaded() {

    let pageLoaded = this;
    return ({
        controller: PageLoadedController,
        restrict: "A",
        link: function (scope, element, attributes) {
            jQuery(document).ready(function() {
                // this will be adding a class for the main blocworx element
                if(jQuery('#pageLoaded')){
                    let blocworx = jQuery('#pageLoaded');
                    var $div = jQuery("<div>", {id: "loaded", "class": "loaded"});
                    blocworx.append($div);

                }
            });
        }
    });
}
