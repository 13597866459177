import * as angular from 'angular';
import StandardComponent from "components/standards/components/StandardComponent";
import VersionController from "./controllers/version.controller";

"use strict";

class BlocworxVersion extends StandardComponent {

    // This will enable the debug mode of this class
    public debug = false;
    public moduleFolder = 'components/blocworx/components/version';

    /**
     * PLease make sure that each function contain correct
     * documentation.
     */
    public bindings = {
        bootstrap: '<',
    }

    // This will set what will be the controller of this component
    public controller : any = VersionController;

    // This will set an alias
    public controllerAs: string = 'vc';
}

angular.module('BlocworxModule').component('blocworxVersion', <StandardComponent> new BlocworxVersion);
