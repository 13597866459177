import * as angular from 'angular';

'use strict';

angular.module('UsersModule')
    .factory('usersService', usersService);

usersService.$inject = [];

function usersService() {

    let service = this;

    /**
     * This will set the current user we are trying to update
     *
     * @param scanStation
     */
    service.setCurrentUserForEditing = (user) => {
        service.user = user;
        return service;
    }

    /**
     * This function will return the current user we want to edit/update
     */

    service.getCurrentUserForEditing = () => {
        return service.user;
    }

    /**
     * Sets the list of users for the page
     * @param users
     */
    service.setListOfUsers = (users) => {
        service.users = users;
        return service;
    }

    /**
     * Gets the most up to date list of users
     * @param users
     */
    service.getListOfUsers = () => {
        return service.users;
    }


    return {
        setCurrentUserForEditing : service.setCurrentUserForEditing,
        getCurrentUserForEditing: service.getCurrentUserForEditing,
        getListOfUsers: service.getListOfUsers,
        setListOfUsers: service.setListOfUsers
    }

}