// Importing all Services

// Main blocworx codes
import './blocworx/services/isIframe.directive'
import './blocworx/services/pageLoaded.directive'
import './blocworx/services/skinSettings.directive'
import './blocworx/services/blocworx.service'
import './blocworx/services/clickLink.directive'
import './blocworx/services/checkChild.directive'
import './blocworx/components/message/services/message.service'

// Form component services
import './blocworx/components/form/services/form.factory'
import './blocworx/components/form/services/prevent.double.click.directive'
import './blocworx/components/form/services/last.field.directive'

// Datetime
import './datetime/services/datetime.service'

// Configuration Module Services
import './configuration/services/configuration.service'

// Sound service
import './sound/services/sound.service'

// Loading blocworx services
import './blocworx/services/url.service'
import './blocworx/services/languages.factory'
import './blocworx/services/alert.factory'
import './blocworx/services/scanStation.service'
import './blocworx/services/status.service'

// This will get the service to start scopes and local variables
import './permission/services/scopeAndLocal.service'
import './permission/services/permission.service'

// Configuring the moment service
import './moment/services/momment.service'

// Prevent template cache on html files service factory
import './prevent-template-cache/services/prevent.template.cache.service'

// Validators Module Services
import './validators/services/validator.service'
import './validators/services/form.validator.service'

// Exception Module Services
import './exceptions/services/exception.service'
import './exceptions/services/argumentUndefined.exception.service'
import './exceptions/services/invalidEmail.exception.service'
import './exceptions/services/missingScanStationFields.exception.service'

// Field Module Services
import './field/services/field.service'
import './field/services/updateData.service'
import './field/services/imageUrlField.service'
import './field/services/inputNumericField.service'
import './field/services/inputTextareaField.service'
import './field/services/totalResultsField.service'
import './field/services/dateTimeSelectorField.service'
import './field/services/htmlField.service'
import './field/services/plainDataField.service'
import './field/services/radioButtonsFromStation.service'
import './field/services/checkboxDynamicCheckedValue.service'
import './field/services/dropdownFromList.service'
import './field/services/timeDifference.service'
import './field/services/maths.service'
import './field/services/fieldMergeBuilder.service'
import './field/services/loggedInUserInfo.service'
import './field/services/alertButtonField.service'
import './field/services/wysiwygForUserField.service'
import './field/services/labelField.service'
import './field/services/checkboxField.service'
import './field/services/buttonField.service'
import './field/services/separatorField.service'
import './field/services/formception2Field.service'
import './field/services/formListField.service'
import './field/services/digitalSignatureField.service'
import './field/services/dropdownFromStationField.service'
import './field/services/autocompleteField.service'
import './field/services/formceptionField.service'
import './field/services/fileField.service'
import './field/services/autoGenerate.service'
import './field/services/blocworxUserNameField.service'
import './field/services/blocworxUserButtonField.service'
import './field/services/blocworxUserEmailField.service'
import './field/services/blocworxUserPasswordField.service'
import './field/services/multiFilesField.service'
import './field/services/jobsListDropdownField.service'
import './field/services/bartenderFileGeneratorField.service'
import './field/services/userSelectField.service'
import './field/services/formSelectField.service'
import './field/services/sectionSelectField.service'
import './field/services/pieChartField.service'
import './field/services/eChartField.service'
import './field/services/crossBlocDataUpdatesField.service'
import './field/components/qrCode/services/qrcode.service'
import './field/components/multiButton/services/multiButton.service'
import './field/components/wysiwyg/services/wysiwyg.service'
import './field/components/mindee/services/mindee.service'
import './field/components/fileBuilder/factories/file-builder.factory'
import './field/components/checkboxFromStation/services/checkboxFromStation.service'
import './field/components/linkData/services/link.service'
import './field/components/createUserPopUp/services/create-user-popup.service'
import './field/components/submitButtonField/services/submit-button.service'
import './field/components/fileBuilder/services/file-builder.service'
import './field/components/exportedFields/services/exportedField.factory'


// Field Rule
import './field-rule/services/fieldRule.service'
import './field-rule/factories/rules.factory'
import './field-rule/factories/fieldRule.factory'
import './field-rule/factories/fieldRuleValidation.factory'

// Field Module Factories
import './field/factories/multiButtonField.factory'
import './field/factories/plainDataField.factory'
import './field/factories/dataFromStationFields.factory'
import './field/factories/field.parameter.factory'
import './field/factories/pieChartField.factory'
import './field/factories/eChartField.factory'
import './field/factories/autoGenerateField.factory'
import './field/factories/dropdownFromField.factory'
import './field/factories/subFormField.factory'
import './field/factories/formListField.factory'
import './field/factories/field.validation.factory'
import './field/factories/digitalSignatureField.factory'
import './field/factories/field.factory'
import './field/factories/crossBlocDataUpdatesField.factory'
import './field/factories/subBlocField.factory'
import './field/components/mindee/factories/mindee.factory'

// Filter Module Services
import './filter/services/filterbyfield.service'

// Grid Module Services
import './grid/services/grid.semaphore.service'
import './grid/services/grid.factory'

// Scanstation Module Services
import './scanstation/services/scanstation.factory'
import './scanstation/services/scanstation.tinymce.service'
import './scanstation/services/scanstation.header.service'
import './scanstation/components/results/factories/results.factory'
import './scanstation/components/results/services/results.service'

// Table order service
import './scanstation/components/tableOrder/services/tableOrder.service'

// Super User Module Services
import './superuser/services/superuser.service'

// Calendar Module Services
import './calendar/services/calendar.factory'

// Icon services
import './icon/services/icon.service'
import './icon/services/icon.factory'

// Page services
import './page/services/page.service'
import './page/services/page.factory'

// Side-Bar services
import './sidebar-pages/services/sidebar-pages.service'
import './sidebar-pages/services/sidebar-pages.factory'

// Url Module services
import './url/services/url.service'

// Help Module services
import './help-mode/services/helpmode.service'
import './help-mode/services/helpmode.factory'

// Drop Drop Services
import './drag-drop/services/dragdrop.service'
import './drag-drop/services/dragdrop.factory'

// Templates Services
import './template/services/template.service'
import './template/services/template.factory'

// Download Services
import './download/services/download.service'
import './download/services/download.factory'

// Roles and Permissions Services
import './roles-and-permissions/services/roles-and-permissions.service'
import './roles-and-permissions/services/roles-and-permissions.factory'

// Module Services and directives
import './module/service/expansible.directive'
import './module/service/module.service'

// Permission services and directives
import './permission/services/showMoreData.directive'
import './permission/services/edit-data-link.directive'

// Export service
import './export/services/export.service'

// scan station component factories
import './scan-station-components/count/factories/count.factory'


// Language service
import './languages/services/language.service'

// Manage inactivity service and factory
import './manage-inactivity/services/manage-inactivity.factory'
import './manage-inactivity/services/manage-inactivity.service'

// Link Field factory
import './field/components/linkData/services/link.factory'

// Reset, Forgot Password service/factories
import './reset-forgot-password/services/reset-forgot-password.factory'

// Create User Pop Up Field
import './field/components/createUserPopUp/services/create-user-popup.factory'

// Submit Button Field
import './field/components/submitButtonField/factories/submit-button.factory'

// Barcode Generator Field
import './field/components/barcodeGenerator/factories/barcodeGenerator.factory'
import './field/components/barcodeGenerator/services/barcodeGenerator.service'

// Manage login attempts service/factories
import './login-attempts/services/login-attempts.factory'
import './login-attempts/services/login-attempts.service'

// Advanced Reports service/factories
import './advanced-reports/services/advanced-reports.service'

// Mail Alert Service & Factory
import './field/components/mailAlert/services/mail-alert.service'
import './field/components/mailAlert/factories/mail-alert.factory'

// Blocworx Gateway Service & Factory
import './field/components/blocworxGateway/services/blocworx-gateway.service'
import './field/components/blocworxGateway/factories/blocworx-gateway.factory'

// Admin service and factory
import './blocworx/components/admin/services/admin.service'
import './blocworx/components/admin/services/appSettings.service'
import './blocworx/components/admin/factories/admin.factory'

import './blocworx/components/admin/services/file.service'
import './blocworx/components/admin/services/theme.service'
import './blocworx/components/admin/factories/theme.factory'
import './blocworx/components/lookup/services/lookup.service'
import './blocworx/components/translation/services/translation.service'

// Two Factor Authentication service
import './two-factor-authentication/services/two-factor-authentication.service'


// Sign In With Google service
import './sign-in-with-google/services/sign-in-with-google.service'

// Page State service
import './blocworx/services/pageState.service'

// Bluetooth
import './field/components/bluetooth/services/bluetooth.service'
import './field/components/bluetooth/factories/bluetooth.factory'

// Field Actions
import './field-actions/services/field-actions.service'
import './field-actions/factories/field-actions.factory'

// External Bloc Data
import './external-bloc-data/services/external-bloc-data.service'
import './external-bloc-data/factories/external-bloc-data.factory'

// Field Conditions
import './field/factories/fieldConditionsFactory.factory';

// Generic Helper
import './blocworx/components/scanStation/factories/scanStationHelper.factory';

// Flow Chart
import './flow-chart/services/flow-chart.service'
import './flow-chart/factories/flow-chart.factory'

// Schedulers Data service
import './schedulers-data/services/schedulers-data.service'

// Managing Users
import './users/services/users.service'
