import * as angular from 'angular';

"use strict";

angular.module('UrlModule')
    .controller('UrlController', UrlController);

// Injection of each class
UrlController.$inject = ['$state', '$scope', 'UrlService', 'ScopeAndLocal', 'RolesAndPermissionsFactory', 'PageFactory'];

// Controller Constructor
function UrlController($state, $scope, UrlService, ScopeAndLocal, RolesAndPermissionsFactory, PageFactory) {

    // Creating the view model reference
    let vm = this;

    vm.$onInit = async function () {
        try {

            ScopeAndLocal.startVariable(vm, $scope, 'page');

            if (typeof ($state.params) != 'undefined' && typeof ($state.params.urlId) != 'undefined') {
                vm.urlId = $state.params.urlId
                vm.pageLink = await UrlService.getPageLinkDataById(vm.urlId)
            }

            vm.getRoles()
            vm.getPages()

        } catch (e) {
            console.log(e);
        }

    }

    vm.getPages = async () => {

        let response = await PageFactory.getPages();

        // if contain data we must add a count of page sections
        if (response.data != undefined && response.data.data != undefined) {

            vm.pageList = response.data.data;
            
        }
    }

    vm.getPageSections = async function (pageId) {
        try {
            let response = await PageFactory.getPageSections(pageId);
            vm.pageSections = response.data.sections;

        } catch (e) {
            console.log(e);
        } finally {
            $scope.$apply();
        }

    }

    vm.getPageLinkDataById = async function (pageLinkId) {
        vm.pageLink = await UrlService.getPageLinkDataById(pageLinkId)
    }

    vm.getRoles = async () => {
        try {
            let data = await RolesAndPermissionsFactory.getRolesAndPermissions()
            vm.rolesAndPermissions = data.data.data

            vm.newRoles = []
            vm.pageLink.role_group.forEach(element => {
                vm.newRoles.push(element.id)
            });


        } catch (e) {
            console.log(e);
        } finally {
            $scope.$apply();
        }
    }

    vm.removeRoleFromNewPage = async function (roleId) {

        vm.newRoles = vm.arrayRemove(vm.newRoles, roleId);

        vm.pageLink.role_group = vm.pageLink.role_group.filter((element) => {
            return element.id != roleId
        })

    }

    vm.addToNewRoles = (newRole) => {
        // includes the role only once
        if (!vm.newRoles.includes(newRole)) {
            vm.newRoles.push(newRole)

            //add the role to the model to show in the html
            vm.rolesAndPermissions.forEach(role => {
                if (role.id == newRole) {
                    vm.pageLink.role_group.push(role)
                }
            });
        }
    }

    vm.arrayRemove = (array, value) => {

        return array.filter(function (element) {
            return element != value;
        });
    }

    vm.updateLinkUrl = async function (id, newLinkName, newLinkUrl, sectionID, orderID, icon, newRoles, newTab, pageId) {
        try {
            await UrlService.updateLinkUrl(id, newLinkName, newLinkUrl, sectionID, orderID, icon, newRoles, newTab, pageId);
            await vm.getPageLinkDataById(id);
            alert('URL Link Details Updated')
        } catch (e) {
            console.log(e);
        } finally {
            $scope.$apply();
        }

    }
}
