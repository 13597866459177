import * as angular from 'angular';
import $ from 'jquery';
import ScanStationController from 'components/blocworx/components/scanStation/controllers/scanstation.controller'

'use strict';

angular.module('BlocworxModule')
    .factory('AlertFactory', AlertFactory);

AlertFactory.$inject = ['$http', 'Configuration', '$mdDialog'];

/**
 * Object that will create a Icon Factory, all methods of factory
 * will be available from outside.
 *
 * @param $http
 * @returns {AlertFactory}
 * @constructor
 */
function AlertFactory($http, Configuration, $mdDialog) {

    let alertFactory = this;
    let alertTimer = 4000;
    let apiUrl = Configuration.getApiUrl();


    /**
     * Constructor method.
     */
    alertFactory.$onInit = () => {
        try
        {
            alertFactory.status = false;
            alertFactory.classToSet = undefined;
            alertFactory.error = false;
            alertFactory.success = false;
            alertFactory.deleteAllDataConfirmationPhrase  = 'DELETEALLDATA'
            alertFactory.alertTimer  = 4000;
            alertFactory.alertID  = 'dialogContainer';

        } catch (exception) {
            console.log(exception);
        }
    }

    /**
     * This will trigger the PHP side of it to delete the data.
     */
    alertFactory.deleteAllData = async (scanStation) => {
        try
        {
            if (alertFactory.deletealldata === alertFactory.deleteAllDataConfirmationPhrase) {

                let apiBase = apiUrl + 'delete-data-scan-station';
                let config = {
                    id: alertFactory.id,
                    password: alertFactory.password,
                    scanStationID: alertFactory.scanStationID,

                };

                // deletion data action
                let success = await $http.post(apiBase, config);

                // after everything is 100% deleted, this will load the data again.
                scanStation.getData(alertFactory.scanStationID);

                // after finish it up should close the window
                alertFactory.closeWindow();

                // after finish we will be cleaning the variables
                alertFactory.cleanAlertVariables();

                $('#dialogSuccessMessage').html(success.data.success);
                alertFactory.success = true;
                $('#dialogSuccess').removeClass('hide').removeClass('fade');

                // this will have some to see the message and after that we need to clean the alert variables, so we can
                // restart the process.
                setTimeout(function () {
                    $('#dialogSuccess').addClass('hide').addClass('fade');
                }, alertFactory.alertTimer);

            } else {
                alertFactory.logError('You must confirm the phrase ' + alertFactory.deleteAllDataConfirmationPhrase);
            }

        } catch (exception) {
            console.log(exception);
            alertFactory.logError(exception.data.error);
        }

    }

    /**
     * This will log all erros that happens
     * @param error
     */
    alertFactory.logError = (error) => {
        $('#dialogErrorMessage').html(error);
        alertFactory.error = true;
        $('#dialogError').removeClass('hide').removeClass('fade');
        setTimeout(function () {
            $('#dialogError').addClass('hide').addClass('fade');
        }, alertFactory.alertTimer);
        alertFactory.status = true;
    }


    /**
     * This will do the actions for a close alert, after successful
     * or when someone click to close the alert.
     */
    alertFactory.cleanAlertVariables = () => {
        alertFactory.status = false;
        alertFactory.password = undefined;
        alertFactory.deletealldata = undefined;
        alertFactory.confirmDeletion = undefined;
        alertFactory.error = false;
        alertFactory.success = false;
    }

    /**
     * This will only do the action of closing the window.
     */
    alertFactory.closeWindow = () => {
        $('#modal').removeClass(alertFactory.classToSet).hide();
        $mdDialog.cancel();
    }

    /**
     * This is the action of cancel of deleteAllData by pressing X,
     * this action we must close any kind of windows and clean all
     * variables, so it can start it over again.
     */
    alertFactory.cancelWithXButton = () => {
        alertFactory.closeWindow();
        alertFactory.cleanAlertVariables();
    }

    /**
     * This will be responsible to open the delete alert
     * are you sure you want to delete?
     */
    alertFactory.openAlert = (event, scanStation) => {

        // set of the class for the #modal get it from the body class
        alertFactory.classToSet = $('body').hasClass('dark') ? 'dark' : 'light';

        // Set of the class so we can have a modal with the current theme
        $('#modal').removeClass().addClass(alertFactory.classToSet).show();

        // show the alert action
        alertFactory.confirmDeletion = true;

        // Showing the alert dialog
        $mdDialog.show({
            controller: ScanStationController,
            controllerAs: 'ctrl',
            templateUrl: 'components/blocworx/template/are-you-sure.html',
            parent: angular.element(document.querySelector('#modal')),
            targetEvent: event,
            clickOutsideToClose: () => {
                $('#modal').removeClass(alertFactory.classToSet).hide();
                return true
            },
            bindToController: true,
            locals : {
                scanStation: scanStation
            },
            preserveScope: true,
        });

    }

    /**
     * This will be responsible to open an alert, this case is specific for the
     * scan station edit.
     *
     * @param event
     * @param scanStation scan station object
     * @param modalConfiguration this contain the configurations for the modal title and
     * modal text that will be printed in.
     * @param parameters these are the parameters to pass on the ok click for the
     * callback function
     * @param callback this is the function that will trigger the ok button action.
     */
    alertFactory.openAlertEdit = (event, scanStation, modalConfiguration, parameters, callback) => {

        // set of the class for the #modal get it from the body class
        alertFactory.classToSet = $('body').hasClass('dark') ? 'dark' : 'light';

        // Set of the class, so we can have a modal with the current theme
        $('#modal').removeClass().addClass(alertFactory.classToSet).show();

        // show the alert action
        alertFactory.confirmDeletion = true;

        // Showing the alert dialog
        $mdDialog.show({
            controller: ScanStationController,
            controllerAs: 'ctrl',
            templateUrl: 'components/blocworx/template/scanStationEdit.html',
            parent: angular.element(document.querySelector('#modal')),
            targetEvent: event,
            clickOutsideToClose: () => {
                $('#modal').removeClass(alertFactory.classToSet).hide();
                return true
            },
            bindToController: true,
            locals : {
                scanStation: scanStation,
                modalConfiguration: modalConfiguration,
                parameters: parameters,
                callback: callback
            },
            preserveScope: true,
        });

    }

    // this will make sure that will be the constructor class like a controller one
    alertFactory.$onInit();

    return alertFactory;
}
