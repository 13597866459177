"use strict";

import * as angular from "angular";

angular.module('BlocworxModule').config(signInWithGoogleRoutes);

signInWithGoogleRoutes.$inject = ['ConfigurationProvider', '$stateProvider'];

function signInWithGoogleRoutes(ConfigurationProvider, $stateProvider) {
    $stateProvider.state('sign-in-with-google', {
        parent: 'app',
        url: '/sign-in-with-google',
        views: {
            'parent': {
                templateUrl: 'components/sign-in-with-google/template/auth-page.html?nd=' + Date.now(),
                controller: 'AuthController as auth'
            }
        },
        params: {
            sidebarHighlighter: 'home'
        }
    });
}