import * as angular from 'angular';
import SchedulersDataController from './controllers/schedulers-data.controller';
import StandardComponent from 'components/standards/components/StandardComponent';

"use strict";

class SchedulersDataComponent extends StandardComponent {

    // This will enable the debug mode for this component
    public debug = false;
    public moduleFolder = 'components/schedulers-data';

    /**
     * Please make sure that each function contains correct
     * documentation.
     */

    public bindings = {
        main: '<',
    }

    // This will set whatwill be the controller of this component
    public controller: any = SchedulersDataController;

    // This will set an alias
    public controllerAs: string = 'schedulersDataController';
}

angular.module('SchedulersDataModule').component('schedulersData', <StandardComponent> new SchedulersDataComponent);