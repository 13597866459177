import * as angular from 'angular';
import StandardComponent from "components/standards/components/StandardComponent";
import TranslationController from "./controllers/translation.controller";

"use strict";

/**
 * Component that will be responsible for the translation.
 */
class BlocworxTranslation extends StandardComponent {

    // This will enable the debug mode of this class
    public debug = false;
    public moduleFolder = 'components/blocworx/components/translation';
    public bindings = {
    }

    // This will set what will be the controller of this component
    public controller : any = TranslationController;

    // This will set an alias
    public controllerAs: string = 'tc';
}

angular.module('BlocworxModule').component('blocworxTranslation', <StandardComponent> new BlocworxTranslation);
