import * as angular from 'angular';
import * as $ from "jquery";

"use strict";

angular.module('UsersModule')
       .controller('UsersController', UsersController);

UsersController.$inject = ['$scope', 'Data', 'Module1UserManagement', 'Auth','$state',
    '$templateCache', '$window', '$interval', '$filter', 'scanStation', 'RolesAndPermissionsFactory','Configuration',
    'ScopeAndLocal','usersService', 'BlocworxTranslationService'];

function UsersController($scope, Data, Module1UserManagement, Auth, $state,
                         $templateCache, $window, $interval, $filter, scanStation, RolesAndPermissionsFactory, Configuration,
                         ScopeAndLocal, usersService, BlocworxTranslationService
    ) {

    let vm = this;
    vm.$onInit = async function () {
        try
        {

            ScopeAndLocal.startVariable(vm, $scope, 'main');
            ScopeAndLocal.startVariable(vm, $scope, 'data');

            vm.id = $state.params.id;
            vm.stateName = $state.current.name;
            vm.translationService = BlocworxTranslationService;
            vm.data.users;
            vm.error;
            vm.dataEntered = false;
            vm.selectedRolesForEditingUser = [];
            vm.userError = false;
            vm.userCanManageUserRoles = false;
            //Added a boolean to check if user validation is correct and an error message
            vm.validInput = true;
            vm.errMsg = "";
            vm.blocworxID = $window.localStorage.getItem('cartolyticsCustomerID');
            vm.profileData;
            vm.currentProfileSelected;
            let randomValue = Configuration.getRandomValue();
            let version = Configuration.getVersion();

            vm.usersListUrl = 'views/manage-users/users-list.html?v=' + version + 'rand=' + randomValue;
            vm.primaryProfileContent = 'views/manage-users/primary-profile-content.html?v=' + version + 'rand=' + randomValue;

            // check if the user is allowed to manage other users roles. If not they can still create operators
            await vm.canManageUserRoles();
            await vm.getCustomers();
            await vm.getRolesAndPermissions()
            vm.createNewUser = false;
            await vm.checkForCreateUserUrlParams();
            $scope.$apply();


        } catch (e) {
            console.log(e);
        }


    }

    /**
     * This rule it is to show the create new user block.
     */
    vm.addNewUSer = () => {
        vm.createNewUser = true;
    }

    /**
     * This rule will be hiding the create new user block.
     */
    vm.closeAddNewUser = () => {
        vm.createNewUser = false;
        vm.newUser.customerName = undefined;
        vm.selectPermissionsNewUser = undefined;
        vm.selectRoleNewUser = undefined;
        vm.newUser.password = undefined;
        vm.newUser.username = undefined;
        vm.newUser.name = undefined;
        vm.newUser.profile_filter = undefined;
    }

    vm.closeUpdateUser = async () => {
        vm.currentProfileSelected.customerName = undefined;
        vm.selectRoleEditingUser = undefined;
        vm.selectPermissionsEditingUser = undefined;
        await vm.loadLatestUsers();
        $scope.$apply();
    }

    vm.canManageUserRoles = async function  () {
        try
        {
            await Auth.hasRole('manage-user-roles')
            vm.userCanManageUserRoles = true;

        } catch (e) {
            console.log(e);
        }

    }

    vm.getPagesForRoles = async function  (id) {
        try
        {
            vm.userError = false;
            let data = await Module1UserManagement.getPagesForRoles(id);
            vm.pagesForRoles = data.data.data;

            angular.forEach(vm.pagesForRoles, function (page) {
                if (typeof (page.hasRole) != 'undefined' && page.hasRole == 1) {
                    vm.selectedRolesForEditingUser.push(page.slug);
                }
            });

            $scope.$apply();

        } catch (e) {
            console.log(e);
        }

    }

    vm.removePermission = async function (permissionSlug) {
        let index = vm.selectedRolesForEditingUser.indexOf(permissionSlug);
        delete vm.selectedRolesForEditingUser[index];
    }

    vm.removeNewUserPermission = async function (permissionSlug) {
        let index = vm.newUser.roles.indexOf(permissionSlug);
        delete vm.newUser.roles[index];
    }

    vm.removeRoleFromExistingUser = async function (roleId) {
        let index = vm.currentRoleGroupsForUser.indexOf(roleId);
        delete vm.currentRoleGroupsForUser[index];
    }

    vm.removeRoleNewNewUser = async function (roleId) {
        let index = vm.newUser.roleGroups.indexOf(roleId);
        delete vm.newUser.roleGroups[index];
    }

    // get all users
    vm.loadLatestUsers = async function  () {
        try
        {
            let users = await Module1UserManagement.getUsers();
            await usersService.setListOfUsers(users.data);
            await vm.getListOfUsers();
            $scope.$apply();

        } catch (e) {
            console.log(e);
        }
    }

    /**
     * Loads the list of users from the serive
     * @returns {Promise<void>}
     */
    vm.getListOfUsers = async function () {
        vm.data.users =  await usersService.getListOfUsers();
        $scope.$apply();
    }

    // get the existing user
    vm.getUser = async function  (id) {
        try
        {
            vm.userError = false;

            let user = await Module1UserManagement.getUser(id);
            vm.userData = Module1UserManagement.data.user_details;
            vm.userData.userRoles = [];
            vm.userData.roleCheck = [];

            for (let i in Module1UserManagement.data.user_roles) {

                // dont add admin to this
                if (Module1UserManagement.data.user_roles[i].role_id != 1) {
                    vm.userData.userRoles.push(Module1UserManagement.data.user_roles[i].role_id);
                    vm.userData.roleCheck[Module1UserManagement.data.user_roles[i].role_id] = 'true';
                }

            }

        } catch (e) {
            console.log(e);
        }
    }

    // check the existing roles for the checkboxes, we need to wait till vm.userData is defined before digging further otherwise errors are thrown
    vm.checkRole = function (role_id) {
        try
        {
            if (typeof (vm.userData) != 'undefined') {
                for (let i in vm.userData.userRoles) {
                    if (vm.userData.userRoles[i] == role_id) {
                        return true;
                    }
                }
            }

        } catch (e) {
            console.log(e);
        }

    }

    vm.addRemoveRole = function (name, role_id) {
        try
        {
            // if is checked this means we simply add the value, else we must remove it from the array
            if (name != 'false') {
                vm.userData.userRoles.push(role_id);
            } else {
                for (let i = 0; i < 20; i++) {
                    if (typeof (vm.userData.userRoles[i]) != 'undefined' && vm.userData.userRoles[i] == role_id) {
                        vm.userData.userRoles.splice(i, 1);
                    }
                }
            }

        } catch (e) {
            console.log(e);
        }

    }


    vm.updateSelectedRoles = function (check, value) {
        try
        {
            // first we check if the box has been checked or unchecked, 0 or undefined means unchecked
            // if unchecked, we remove it from the original array
            if (typeof (check) == 'undefined' || check == 0) {
                angular.forEach(vm.selectedRolesForEditingUser, function (item, key) {
                    if (item == value) {
                        vm.selectedRolesForEditingUser.splice(key, 1);
                    }
                });
                // if checked we first see is it already in the array already, if so do nothing, otherwise add it
            } else {
                vm.isInRoleArrayAlready = 0;
                angular.forEach(vm.selectedRolesForEditingUser, function (item, key) {
                    if (key == value) {
                        vm.isInRoleArrayAlready = 1;
                    }
                });
                if (vm.isInRoleArrayAlready == 0) {
                    let key = value;
                    let obj = {};
                    obj[key] = 1;
                    vm.selectedRolesForEditingUser.push(value);
                }
            }

        } catch (e) {
            console.log(e);
        }

    }

    //Method that validates the users' inputted password if it isn't valid an alert will show along with a red error box
    vm.validatePassword = function (password) {
        try
        {
            if (!password) {
                vm.errMsg = "Password must be non-empty";
                vm.validInput = false;
                return false;
            }

            let specialCharactersCount =  vm.countSpecialCharacters(password);
            let hasLowerCaseCharacter = /[a-z]/.test(password);
            let hasUpperCaseCharacter = /[A-Z]/.test(password);
            let hasDigitCharacter = /[0-9]/.test(password);

            if (hasLowerCaseCharacter && hasUpperCaseCharacter && hasDigitCharacter && specialCharactersCount>1 && password.length >= 8) {
                vm.validInput = true;
                vm.errMsg = "";
                return true;
            }

            vm.validInput = false;
            vm.errMsg = "Password must contain at least 1 uppercase, 1 lowercase, 1 digit and 2 special characters from [£$&+,:;=?@#|'<>.^*()%!-] and be at least 8 characters long.";
            return false;

        } catch (e) {
            console.log(e);
        }

    }

    //Method that checks if there is an app setting that requires a role for a user
    vm.validateUserRoleRequirements = async function (roleGroups) {
        try
        {
            let requireRole = await Data.hasAppSetting('requires_role_for_user',1);

            // remove empty slots after selecting and deselecting roles
            let temp = [];
            for(let i of roleGroups) {
                i && temp.push(i);
            }

            roleGroups = temp;

            if(!requireRole) {
                return true;
            } else {
                console.log(roleGroups.length)
                if(roleGroups.length < 1) {
                    vm.validInput = false;
                    vm.errMsg = "At least one user role is required";
                    return false;
                }
            }

            return true;

        } catch (e) {
            console.log(e);
        }

    }

    /**
     * This will be responsible to count special characters.
     * @param password
     */
    vm.countSpecialCharacters = function (password : string){

        // function decorator to get unique characters
        const uniqueCharacters = e => [...new Set([...e])].sort().join("");

        // cleaning the repetition of any kind of character
        password = uniqueCharacters(password);

        return (password.match(/[£$&+,:;=?@#|'<>.^*()%!-]/g) || []).length;
    };

    vm.checkUser = async function (userName) {
        try
        {
            let userIsAvailable = true;
            angular.forEach(vm.data.users, function (user) {
                if (userName == user.email) {
                    vm.validInput = false;
                    vm.errMsg = "That username already exists";
                    userIsAvailable = false;
                }
            });

            return userIsAvailable;

        } catch (e) {
            console.log(e);
        }

    }

    // add new user
    vm.addUser = async function () {
        try
        {
            if (vm.newUser.is_user == 1) {
                if (!vm.validatePassword(vm.newUser.password)) {
                    return false;
                }

                let requiresUserRoles = await vm.validateUserRoleRequirements(vm.newUser.roleGroups);
                $scope.$apply();

                if(!requiresUserRoles) {
                    return false;
                }
            }

            let userExists = await vm.checkUser(vm.newUser.username);
            if(userExists == false) {
                return false;
            }

            vm.userError = false;

            await Module1UserManagement.addUser(vm.newUser);

            vm.createNewUser = false;
            vm.newUser = {};

            alert('User Added');

            await vm.loadLatestUsers();
            $scope.$apply();

            if(vm.data.creatingUserFromBloc) {
                window.parent.postMessage('closeAddUserIframe', '*');
            }
            vm.closeAddNewUser();

        } catch (e) {
            console.log(e);

            // TODO: Missing first argument here
            let data = await Auth.checkSuperUser();

            // TODO: this whole action on a catch isn't a good thing, as if this throws an exception we might not catch it
            if (data.data.data === 1) {
                let addToInstance = confirm('Would you like to add this user to this instance');
                if (addToInstance) {

                    // TODO: this whole action on a catch isn't a good thing, as if this throws an exception we might not catch it
                    await Module1UserManagement.addToInstance(vm.newUser);
                    await vm.loadLatestUsers();

                    vm.newUser = [];
                    alert('User Added To Instance');
                }

            } else {
                vm.errMsg = e.data.error;
                vm.validInput = false;
                vm.userError = e.data.error;
                $scope.$apply();
            }
        }

    }

    // delete User
    vm.deleteUser = async function  (id) {
        try
        {
            vm.userError = false;
            await Module1UserManagement.deleteUser(id);
            await vm.loadLatestUsers();
            vm.data.showMoreUserPopUp = false;
            $scope.$apply();
            alert('User Deleted');

        } catch (e) {
            console.log(e);
        }

    }

    vm.getCustomers = async function  () {
        try
        {
            let data = await Data.getCustomers();
            vm.customers = data.data.data;

        } catch (e) {
            console.log(e);
        }

    }



    vm.clickShowMoreAction = async (user) => {
        vm.data.showMoreUserPopUp = true;
        await usersService.setCurrentUserForEditing(user);
        $scope.$apply();
    }

    /**
     * This function will load the current user we are trying to edit
     */

    vm.getCurrentUserForEditing = async function() {
        vm.currentProfileSelected = await usersService.getCurrentUserForEditing();
        await vm.getPagesForRoles(vm.currentProfileSelected.id);
        let currentRoleGroupsForUser  = await Module1UserManagement.getRoleGroupsForUser(vm.currentProfileSelected.id);
        vm.currentRoleGroupsForUser = currentRoleGroupsForUser.data.data;
        $scope.$apply();
    }


    vm.manageProfilesEditUser = async function  (userData, newPassword) {
        try
        {
            if (typeof newPassword != "undefined") {
                userData.password = newPassword;
            }

            if (userData.password) {
                if (!vm.validatePassword(userData.password)) {
                    return;
                }
            }

            let requiresUserRoles = await vm.validateUserRoleRequirements(vm.currentRoleGroupsForUser);
            $scope.$apply();

            if(!requiresUserRoles) {
                return false;
            }

            vm.userError = false;
            userData.roles = vm.selectedRolesForEditingUser;
            userData.roleGroups = vm.currentRoleGroupsForUser;
            userData.username = vm.currentProfileSelected.email;
            userData.name = vm.currentProfileSelected.name;
            userData.email_address = vm.currentProfileSelected.email_address;
            userData.profile_filter = vm.currentProfileSelected.profile_filter;
            userData.customerName = null;
            userData.canLogInAutomatically = vm.currentProfileSelected.can_log_in_automatically;
            await Module1UserManagement.editUser(userData);
            await vm.loadLatestUsers();
            alert('User Info Updated');
            vm.data.showMoreUserPopUp = false;
            await vm.closeUpdateUser();

            $scope.$apply();

        } catch (e) {
            console.log(e);
            vm.userError = e.data.error;
        }

    }

    vm.getBase64Image = async function(jobID,stationID,fileName,fieldSlug, index) {
        try
        {
            console.log(fileName);
            let data = await scanStation.getBase64Image(jobID,stationID,fileName);
            if(typeof(vm.profileImages=='undefined')) {
                vm.profileImages=[];
            }
            console.log(index);
            console.log(fileName + '_' +index);
            vm.profileImages[fileName + '_' +index] = data.data.data;
            $scope.$apply();

        } catch (e) {
            console.log(e);
        }
    }

    vm.getRolesAndPermissions = async function () {
        try {
            let data = await RolesAndPermissionsFactory.getRolesAndPermissions();
            vm.rolesAndPermissions = data.data.data;

        } catch (e) {
            console.log(e);
        }
    }

    vm.checkForCreateUserUrlParams = async function () {
        try {
            if($state.params.fromBlocId != null) {
                vm.data.creatingUserFromBloc = true;
                vm.createNewUser = true;
                vm.newUser = {};
                vm.newUser.name = $state.params.name;
                vm.newUser.username = $state.params.username;
                vm.newUser.profile_filter = $state.params.profileFilter;
            }

        } catch (e) {
            console.log(e);
        }
    }

    vm.scrollTop = function () {
        try {
            $('html, body').animate({
                scrollTop: "0px"
            }, 800);

        } catch (e) {
            console.log(e);
        }

    };

}

export default UsersController;
