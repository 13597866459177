import * as angular from 'angular';

"use strict";

angular.module('BlocworxModule')
       .directive('preventNgClick', preventNgClick);

preventNgClick.$inject = [];

function preventNgClick() {

    return ({
        restrict: "A",
        scope: {
            preventNgClick: '&'
        },
        link: function(scope, iElement, iAttrs) {
            iElement.bind('click', function() {
                iElement.prop('disabled',true);
                scope.preventNgClick().finally(function() {
                    iElement.prop('disabled',false);
                })
            });
        }
        
    });
}