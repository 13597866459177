import * as angular from 'angular';
import Language from "components/languages/controllers/language";

'use strict';

angular.module('BlocworxModule')
    .factory('LanguagesFactory', LanguagesFactory);

LanguagesFactory.$inject = ['$window', 'Configuration', 'Languages', '$http', 'Data'];


function LanguagesFactory($window, Configuration, Languages, $http, Data) {

    let LanguagesFactory = this;
    let apiUrl = Configuration.getApiUrl();
    LanguagesFactory.debug = false;

    let languages = {
        "en_EN": "English",
        "de_DE": "German",
        "fr_FR": "French",
        "hr": "Croatian",
        "pt_BR": "Portuguese",
        "sv_SE": "Swedish",
        "pl": "Polish",
    }

    /**
     * Constructor method.
     */
    LanguagesFactory.$onInit = () => {
        try
        {
            LanguagesFactory.translatedWords = undefined;
            LanguagesFactory.selectedLanguage = undefined;
            LanguagesFactory.languages = [];

            for (const [index, [key, value]] of Object.entries(Object.entries(languages))) {
                let languageObject = new Language(key,value);
                LanguagesFactory.languages = [...LanguagesFactory.languages, languageObject]
            }

        } catch (exception) {
            console.log(exception);
        }
    }

    /**
     * This will return the language name by the code of it.
     * @param code
     */
    LanguagesFactory.getLanguageByCode = (code) =>{

        let language = "en_EN";

        for (const [index, [key, value]] of Object.entries(Object.entries(languages))) {
            if(key == code){
                language = value;
                break;
            }
        }
        return language;

    }

    /**
     * This will return the array of data from the languages variable.
     */
    LanguagesFactory.getLanguagesOptions = async () =>{
        return LanguagesFactory.languages;
    }

    /**
     * This will load the current selected language
     */
    LanguagesFactory.loadCurrentSetLanguage = async () =>
    {
        try
        {
            let defaultLanguage = 'en_EN';
            LanguagesFactory.selectedLanguage = defaultLanguage

            let cartolyticsCustomerID =  await Data.getCartolyticsCustomerID();

            // loading the current language
            let language = await  $http.get(apiUrl + 'get-current-language', { params: {
                cartolyticsCustomerID: cartolyticsCustomerID
            }});

            if(language.data != undefined && language.data.language != undefined) {
                LanguagesFactory.selectedLanguage =  language.data.language;
            }

        } catch (e) {
             console.log(e);
        } finally {
            return LanguagesFactory.selectedLanguage
        }

    }

    /**
     * This will bootstrap a language.
     *
     * @param reload
     */
    LanguagesFactory.bootstrapLanguages = async (reload = false) => {

        // This will load if we say to reload or on the bootstrap of the main language
        if(LanguagesFactory.translatedWords == undefined || reload) {

            // loading what was saved
            let languageSetting = await LanguagesFactory.loadCurrentSetLanguage();

            // loading the json file
            let randomValue = Configuration.getRandomValue();
            let translatedWordsJson = await $http.get('/language-files/' + languageSetting + '.json?rand=' + randomValue, {});

            if(translatedWordsJson.data != undefined){
                LanguagesFactory.translatedWords = translatedWordsJson.data;
            }
        }

    }

    /**
     * Method that will trigger the load of the languages.
     *
     * @param loggedIn
     */

    LanguagesFactory.loadLanguages = async (loggedIn) => {
        try
        {
            let localStorageWords = $window.localStorage.getItem('translatedWords');

            if(localStorageWords != '{}' && localStorageWords != null && localStorageWords != 'undefined') {
                LanguagesFactory.translatedWords = JSON.parse(localStorageWords);
            } else {

                let isLoggedIn = loggedIn;
                let translatedWordsJson = await Languages.getWords(isLoggedIn);
                LanguagesFactory.translatedWords = translatedWordsJson.data;
            }

        } finally {

            // this is to make sure that debug can be done at the end, so we can guarantee to see the correct data
            if(LanguagesFactory.debug){
                console.log('vm.translatedWords');
                console.log(LanguagesFactory.translatedWords);
            }

            // setting the translateWords into the localStorage
            window.localStorage.setItem('translatedWords', JSON.stringify(LanguagesFactory.translatedWords));
        }
    }

    /**
     * This will be responsible to return data for the variable
     * LanguagesFactory.translatedWords
     */
    LanguagesFactory.getTranslatedWords = () => {
        return LanguagesFactory.translatedWords;
    }

    // this will make sure that will be the constructor class like a controller one
    LanguagesFactory.$onInit();

    return LanguagesFactory;
}
