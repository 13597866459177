import './permission/component';
import './blocworx/components/authentication/component';
import './blocworx/components/forbidden/component';
import './blocworx/components/navbar/component';
import './blocworx/components/scanStation/component';
import './blocworx/components/logo/component';
import './blocworx/components/copyright/component';
import './blocworx/components/form/component';
import './blocworx/components/table/component';
import './blocworx/components/message/component';
import './blocworx/components/admin/component';
import './blocworx/components/user/component';
import './blocworx/components/translation/component';
import './blocworx/components/version/component';
import './blocworx/components/lookup/component';
import './datetime/component';
import './page-bar/component';
import './icon/component'
import './breadcrumb/component';
import './back-button/component';
import './field/component';
import './field-rule/component';
import './field-rule-hover/component';
import './help-mode/component';
import './grid/component'
import './grid/components/grid-delete-row/component'
import './grid/components/grid-field/component'
import './template/component'
import './download/component'
import './roles-and-permissions/component'
import './calendar/component'
import './module/component'
import './field/components/crossBlocDataUpdates.component';
import './field/components/qrCode/component';
import './field/components/wysiwyg/component';
import './field/components/mindee/component';
import './field/components/customerDetails/component';
import './field/components/radioButton/component';
import './field/components/multiButton/component';
import './field/components/scanStationField/component';
import './field/components/checkboxFromStation/component';
import './users/component';
import './field/components/subbloc/component';
import './field/components/linkData/component';
import './users/component';
import './export/component'
import './scan-station-components/count/component';
import './field/components/createUserPopUp/component';
import './field/components/submitButtonField/component';
import './advanced-reports/component';
import './field/components/mailAlert/component';
import './field/components/fileBuilder/component';
import './field/components/sourceBloc/component';
import './field/components/matchingData/component';
import './field/components/exportedFields/component';
import './field/components/barcodeGenerator/component';
import './field/components/blocworxGateway/component';
import './field/components/bluetooth/component';

// This is the Scan Station Components
import './scan-station-components/count/component';

import './detect-inactivity/component';
import './manage-inactivity/component';
import './login-attempts/component';

import './reset-forgot-password/component';
import './management/component';
import './blocworx/components/navbar/components/selected/component';

import './scanstation/components/tableOrder/component';
import './scanstation/components/results/component';
import './sidebar-pages/component';

// Importing buttons components
import './buttons/components/addNew/component';

// Languages component
import './languages/component';

import './sign-in-with-google/component';
// sockets components
import './sockets/components/getLatestData/component'

// field actions component
import './field-actions/component';

// external bloc data component
import './external-bloc-data/component';

// flowchart component
import './flow-chart/component';

// Scheduler Data Component
import './schedulers-data/component'
