import * as angular from 'angular';
import * as $ from "jquery";

"use strict";

angular.module('BlocworxModule')
    .controller('BootstrapController', BootstrapController);

BootstrapController.$inject = ['$http', 'Auth', '$auth', '$location', '$window', '$state',
    '$rootScope', '$scope', '$interval', 'Data', '$sce', 'Configuration', 'GridFactory',
    'Validator', 'datetime', 'LanguagesFactory', 'ThemeService', 'ScanStationTinyMceService', 'SCS', 'AppSettings'];

function BootstrapController($http, Auth, $auth, $location, $window, $state,
                             $rootScope, $scope, $interval, Data, $sce, Configuration, GridFactory,
                             Validator, datetime, LanguagesFactory, ThemeService, ScanStationTinyMceService, SCS, AppSettings) {

    let version = Configuration.getVersion();
    let subDomain = Configuration.getSubDomain();
    let randomValue = Configuration.getRandomValue();

    let country = '';
    let flag = '';
    let vm = this;
    vm.isAdmin = false;

    vm.$onInit = async function () {
        try
        {
            // console.log('Start: Bootstrap Controller');
            vm.subDomain = subDomain;
            vm.versionUpdateRequired = false;
            vm.tinyMceService = ScanStationTinyMceService;
            vm.dataType = $state.current.name;
            vm.auth = Auth;
            vm.userSetting = Data.userSetting;
            vm.appSettings = AppSettings;
            await vm.appSettings.load();
            await vm.isLoggedIn(vm.dataType, $state);
            await vm.auth.loadSuperUser();

            // loading the languages
            vm.languages = LanguagesFactory;
            await vm.languages.bootstrapLanguages(vm.auth.loggedIn);

            // starting the datetime configuration
            datetime.configuration();
            vm.checkInIFrame();
            vm.loadPageTypeResult = vm.loadPageType();

            // loading the theme service to load the header background
            vm.theme=ThemeService;
            await vm.theme.getHeaderBackground();

            vm.url = window.location.href;
            vm.validator = Validator;

            vm.country = country;
            vm.flag = flag;
            $rootScope.$$listeners.$stateChangeStart = [];
            vm.sideBarStatus = 'closed';
            vm.version = version;
            vm.versionUpdateRequiredPopUp = 'closed';

            // this is the rule for bootstrap the compare version function
            await vm.compareVersionBootstrapRule();

            $rootScope.$on('$stateChangeStart', async function (event, toState) {
                if (toState.name != 'auth') {
                    await vm.isLoggedIn(toState.name, toState, event);
                }
            });


            if (vm.auth.loggedIn == true) {
                if (vm.dataType != 'auth' && vm.dataType != 'forgot-password' && vm.dataType != 'reset-password') {
                    await vm.getCartolyticsCustomerShowOptions();
                    await vm.getUserRoles();
                    vm.isAdmin = vm.userRoles.includes('admin') || vm.userRoles.includes('admin-landing-page') || vm.userRoles.includes('admin-front-end');
                }
                await vm.getUserSetting('help_mode');
                await vm.getUserSetting('skin_setting');
            }

        } catch (e) {
            console.log(e);
        }
    }

    /**
     * This is a two step authentication that happens every time a page loads or a link is clicked to change view.
     * First step is to verify the user is logged in, second step is they have the correct permissions for the page
     * they are trying to access.
     * This process is also done on the server side with its own data. This means that even if the user successfully
     * got to a page when they shouldn't have they will still not be able to retrieve or add any data.
     */
    vm.reloadPage = function () {
        try {
            $window.location.reload();
        } catch (e) {
            console.log(e);
        }
    }

    /**
     * This will reload the new version action, changing a few parameters versionUpdateRequired
     * and versionUpdateRequiredPopup, and later reloading the page by Javascript window reload.
     */
    vm.reloadNewVersion = function () {
        try {
            vm.versionUpdateRequired = false;
            vm.versionUpdateRequiredPopUp = 'closed';
            $window.location.reload();
        } catch (e) {
            console.log(e);
        }
    }

    /**
     * This will return if the process of login was made or not.
     */
    vm.isLogged = () => {
        const logged = JSON.parse(sessionStorage.getItem('logged'));
        return logged === true ? true : false;
    }

    /**
     * Actions that we do after comprove that a user is logged in.
     *
     * @param role
     * @param toState
     * @param event
     */
    vm.isLoggedInAction = async (role, toState) => {

        if (typeof (toState.params) != 'undefined') {
            // if the page has a parent instead use that parent as the role verification. This assumes the parent owns the role of the sub page
            if (toState.params.usesRole) {
                role = toState.params.usesRole;
            }
        }

        // this will check of logged in, if true we will be having data on Auth.userData
        await Auth.isLoggedIn();
        if (Auth.userData) {

            vm.auth.loggedIn = true;
            if (sessionStorage.getItem('logged') === null && location.pathname !== '/logout') {
                sessionStorage.setItem('logged', 'true');
            }

            vm.username = Auth.userData.name;
            vm.email = Auth.userData.email;

            // Setting User ID
            vm.userID = Auth.userData.id;
            $window.localStorage.setItem('userID', vm.userID);

            // setting isSuperUser
            $window.localStorage.setItem('isSuperUser', Auth.userData.super_user);
            vm.isSuperUser = $window.localStorage.getItem('isSuperUser');

            // setting isCustomer
            vm.customerName = Auth.userData.customer_name;
            vm.isCustomer = Auth.isCustomer();

            await vm.getUserRoles();

            // this will load user settings
            await vm.getUserSetting('skin_setting');

            // this will be checking the role for the user
            let hasRole = await vm.hasRole(role, toState);
            if (!hasRole) {
                if (vm.userRoles == null) {
                    vm.roleFailed = true;
                }
                if (vm.userRoles != null && !vm.userRoles.includes($state.current.name)) {
                    vm.roleFailed = true;
                }
                if (vm.userRoles != null && vm.userRoles.includes($state.current.name)) {
                    vm.roleFailed = false;
                }
            }

            // adding this to local storage to remove the isLoggedIn from the MainController
            $window.localStorage.setItem('roleFailed', vm.roleFailed);

            vm.cartolyticsCustomerID =  await Data.getCartolyticsCustomerID();
            $window.localStorage.setItem('cartolyticsCustomerID', vm.cartolyticsCustomerID);

        } else {
            vm.auth.loggedIn = false;
        }
    }

    /**
     * This is the action that will be done if a user can't have the
     * Auth token, so we must do some actions, this fucntion is to get all
     * of the steps.
     *
     * @param role
     * @param toState
     * @param event
     */
    vm.isNotLoggedInAction = async () => {

        if (vm.dataType == 'public-form') {

            await vm.publicLogin();

        } else if (vm.dataType != 'auth' && vm.dataType != 'forgot-password' && vm.dataType != 'reset-password' && vm.dataType != 'sign-in-with-google') {

            // this is the basic path when
            let path = $location.path() == '/logout' || $location.path() == '/auth' ? '/' : $location.path();

            // if we can find a redirect on the local storage, we must change to that redirect path
            let redirect = $window.localStorage.getItem('redirect');
            if (redirect != null) {
                path = redirect;
            }

            $window.localStorage.setItem('redirect', path);
            $state.go('auth',{
                'inactivitylogout' : $state.params.inactivitylogout
            });

        }
    }

    vm.isLoggedIn = async function (role, toState) {
        try {

            // first check if token exists in local storage
            vm.token_exists = Auth.tokenExists();

            // if token this exist then check if its valid by connecting to server
            vm.token_exists ? await vm.isLoggedInAction(role, toState)
                            : await vm.isNotLoggedInAction();

            $scope.$apply();

        } catch (e) {

            console.log(e);

            if (vm.dataType == 'public-form') {
                await vm.publicLogin();
            } else {

                if ($location.path() != '/auth') {

                    let path = '/';
                    if ($location.path() != '/logout') {
                        path = $location.path();
                    }

                    $window.localStorage.setItem('redirect', path);
                    $state.go('auth',{
                        'inactivitylogout' : $state.params.inactivitylogout
                    });
                }
            }
        }

    };

    vm.publicLogin = async function () {
        try {
            let credentials = {
                email: vm.subDomain,
                password: 'public-pass'
            };

            await $auth.login(credentials);
            $window.location.reload();

        } catch (e) {
            console.log(e);
        }

    }

    vm.hasRole = async function (role, toState) {
        try {
            await Auth.hasRole(role);
            vm.roleFailed = false;
            $scope.$apply();
            return true;

        } catch (e) {
            console.log(e);
        }

    }

    /**
     * This will be running each hour to check the version of the
     * software, this must be loaded once in the main init of
     * the controller, so we can guarantee that check at the first time
     * on the boot, and later on each hour again.
     *
     */
    vm.compareVersionBootstrapRule = async () => {

        // this is to guarantee that when we load the boostrap this runs once
        await vm.compareVersion();

        // this will run each hour
        $interval(() => vm.compareVersion(), 3600000);
    }

    vm.compareVersion = async function () {
        try {
            await Auth.compareVersions(vm.version);
            vm.versionUpdateRequired = false;

        } catch (e) {
            console.log(e);
            vm.versionUpdateRequiredPopUp = 'open';
            vm.versionUpdateRequired = true;
            if ((typeof (e.data) == 'undefined' || e.data == null)) {
                vm.versionUpdateMessage = 'Disconnected, please refresh.';
            } else {
                if(e.data != null) {
                    vm.versionUpdateMessage = e.data.error;
                } else {
                    vm.versionUpdateMessage = 'Unknown Error, please refresh or contact support@blocworx.com';
                }
            }
        } finally {
            $scope.$apply();
        }

    }

    vm.getAlternativeStateName = async function (stateName) {
        try {
            let data = await Data.getAlternativeStateName(stateName);
            vm.alternativeStateName = data.data.alternativeName;

        } catch (e) {
            console.log(e);
        }

    }

    vm.getCartolyticsCustomerShowOptions = async function () {
        try {
            // Here we are fetching the list of items that can be shown for this customer (only if applicable, as most items will be shown for everyone)
            vm.cartolyticsCustomerShowOptions = localStorage.getItem('cartolyticsCustomerShowOptions');
            if (vm.cartolyticsCustomerShowOptions == null) {

                let data = await Data.getCartolyticsCustomerShowOptions();
                $window.localStorage.setItem('cartolyticsCustomerShowOptions', JSON.stringify((data.data.data)));
                vm.cartolyticsCustomerShowOptions = data.data.data;

            } else {
                // they were already retrieved so no need to call the API again
                let cartolyticsCustomerShowOptionsJSON = localStorage.getItem('cartolyticsCustomerShowOptions');
                vm.cartolyticsCustomerShowOptions = JSON.parse(cartolyticsCustomerShowOptionsJSON);
            }

        } catch (e) {
            console.log(e);
        }

    }

    vm.checkCartolyticsCustomerShowOption = function (showOption, defaultResult) {
        try {
            if (vm.cartolyticsCustomerShowOptions != null && vm.cartolyticsCustomerShowOptions.includes(showOption)) {
                return true;
            } else {
                return defaultResult;
            }

        } catch (e) {
            console.log(e);
        }

    }

    /**
     * @deprecated
     * @param languageSetting
     */
    vm.updateLanguageSetting = async function (languageSetting) {
        try {
            vm.languageSettingToUpdateTo = languageSetting;
            await Data.updateLanguageSetting(languageSetting);
            vm.languageSetting = vm.languageSettingToUpdateTo;
            vm.reloadPage();

        } catch (e) {
            console.log(e);
        }

    }

    vm.getUserRoles = async function () {
        try {

            vm.userRoles = await Auth.getUserRoles();

        } catch (e) {
            console.log(e);
        } finally {
            $scope.$apply();
        }
    }

    vm.pageContentStart = async () => {

        // Added this validation due to on homepage this is requested
        if (Auth.tokenExists()) {
            await vm.getUserSetting('help_mode');
            await vm.getUserSetting('skin_setting')
        }
    }

    vm.getUserSetting = async function (key) {
        try {
            // we assume that the user is logged in if an iframe is appearing
            if (vm.auth.loggedIn == true || vm.inIframe == true) {
                if(Data.userSetting[key] == undefined){
                    let data = await Data.getUserSetting(key);
                    vm.userSetting[key] = data.data.data;
                }
            }

        } catch (e) {
            console.log(e);
        }

    }

    vm.updateUserSetting = async function (key, value) {
        try {
            await Data.updateUserSetting(key, value);
            vm.userSetting[key] = value;

        } catch (e) {
            console.log(e);
        }

    }

    vm.updateHelpModeSetting = function () {
        try {
            if (vm.userSetting['help_mode'] === 'off') {
                vm.userSetting['help_mode'] = 'on';
            } else {
                vm.userSetting['help_mode'] = 'off';
            }

        } catch (e) {
            console.log(e);
        }

    }

    /**
     * This will reload the tiny MCEEditor
     */
    vm.reloadTinyMCEEditor = async function () {

        try {
            vm.tinyMceService.setTinyMCETemplate();
            let body = $('iframe').contents().find('#tinymce');

            if ($(body).hasClass('skin-setting-bright')) {
                $(body).addClass('skin-setting-dark').removeClass('skin-setting-bright')
            } else {
                $(body).addClass('skin-setting-bright').removeClass('skin-setting-dark')
            }

            $scope.$broadcast('$tinymce:refresh');

        } catch (e) {
            console.log(e);
        }

    }

    /**
     * This will switch the skin colors of the website.
     */
    vm.switchSkin = async () => {
        try {

            vm.updateSkinSettingMode();
            await vm.updateUserSetting('skin_setting', vm.userSetting['skin_setting']);
            await vm.reloadTinyMCEEditor();

        } catch (e) {
            console.log(e);
        } finally {
            $scope.$apply();
        }
    }

    vm.updateSkinSettingMode = function () {
        try
        {
            // If we cant find, means it is a new user, so we must get it from the html the first loaded value
            if(vm.userSetting['skin_setting'] == undefined || vm.userSetting['skin_setting'] == false){
                vm.userSetting['skin_setting'] = $('.main-container').attr("class").match(/skin-setting[\w-]*/)[0].replace(/skin-setting-/g,"")
            }

            // removing what we had before
            $('.main-container').removeClass('skin-setting-' + vm.userSetting['skin_setting']);

            switch (vm.userSetting['skin_setting']) {
                case 'dark' :
                    vm.userSetting['skin_setting'] = 'bright';
                    break;

                case 'bright':
                    vm.userSetting['skin_setting'] = 'dark';
                    break;
            }

            // update the new value
            $('.main-container').addClass('skin-setting-' + vm.userSetting['skin_setting']);


        } catch (e) {
            console.log(e);
        }

    }

    vm.checkForAuth = function () {
        try {
            return $state.current.name === 'auth';
        } catch (e) {
            console.log(e);
        }
    }

    vm.checkInIFrame = function () {
        try {

            if (window.self === window.top) {
                vm.inIframe = false;
                vm.isInIframe = '';
            } else {
                vm.inIframe = true;
                vm.isInIframe = 'is-in-iframe';
            }

        } catch (e) {
            console.log(e);
        }

    }

    // Method that will turn on the grid
    vm.gridSemaphoreURL = function () {
        try {
            // TODO: check the whole logic with the grid!!
            return GridFactory.gridSemaphoreURL();
        } catch (e) {
            console.log(e);
        }

    }

    /**
     * This function is related to upload a logo in the main
     * sidebar menu.
     *
     */
    vm.uploadLogo = async function () {
        try
        {
            let logoFile = vm.newLogo;
            let data = await Data.uploadLogo(logoFile);

            if (typeof data.data.error != 'undefined' && data.data.error == 'png-error') {
                alert('Please upload a png file')
            }

            vm.randomLogoAppend = Math.random() * 100;
            vm.updateLogoPopUp = false;

            if(data.status == 200 && data.data != undefined && data.data.success != undefined){
                vm.customerLogo = await SCS.loadLogo(vm.subDomain + `.png`, vm.subDomain);
                await vm.appSettings.updateAppSettings('sidebar_logo', vm.customerLogo)
            }
            vm.enabledLogo = true;

        } catch (e) {
            console.log(e);
            vm.updateLogoPopUp = false;
            vm.pleasewait = false;
        } finally {
            $scope.$apply();
        }
    }

    /**
     * This function will be checking
     */
    vm.loadSiteSettings = () => {

        let siteSettingsCss = ["container"];

        if(vm.userSetting != undefined && vm.userSetting['skin_setting'] != undefined && vm.userSetting['skin_setting'] != false){
            siteSettingsCss = [...siteSettingsCss, `skin-setting-${vm.userSetting['skin_setting']}`]
        } else {
            siteSettingsCss = [...siteSettingsCss, `skin-setting-dark`]
        }

        if(vm.userSetting != undefined && vm.userSetting['help_mode'] != undefined && vm.userSetting['help_mode'] != false){
            siteSettingsCss = [...siteSettingsCss, `help-mode-${vm.userSetting['help_mode']}`]
        } else {
            siteSettingsCss = [...siteSettingsCss, `help-mode-off`]
        }

        if(vm.subDomain != undefined && vm.subDomain != false) {
            siteSettingsCss = [ ...siteSettingsCss, `blocworx-customer-${vm.subDomain}` ]
        }

        siteSettingsCss = [...siteSettingsCss, "main-container"];

        return siteSettingsCss.join(" ");
    }

    /**
     * This function will be responsible to
     * check if it is on an iframe or not,
     * if the isInIframe is equal to '' we are on the main page, otherwise
     * will be an Iframe for a sub block field type.
     */
    vm.loadPageType = () => {
        vm.checkInIFrame();
        return vm.isInIframe == '' ? 'blocworx' : 'iframe'
    }

    vm.loadHelpMode = () => {
        // help-mode-{{bootstrap.userSetting['help_mode']}} blocworx-customer-{{bootstrap.subDomain}} main-container"
    }

}

export default BootstrapController;