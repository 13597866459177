import {DoBootstrap, NgModule, Inject} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {UpgradeModule, setAngularJSGlobal} from '@angular/upgrade/static';
import 'zone.js';
import 'zone.js/dist/long-stack-trace-zone.js';
import * as angular from 'angular';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {ReactiveFormsModule} from '@angular/forms';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatDialogModule} from '@angular/material/dialog';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouterModule} from '@angular/router';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatInputModule} from '@angular/material/input';
import {MatNativeDateModule} from '@angular/material/core';
import {MatSelectModule} from '@angular/material/select';
import {LayoutModule} from '@angular/cdk/layout';
import {DateAdapter, MAT_DATE_FORMATS} from '@angular/material/core';
import {MAT_DATE_LOCALE} from '@angular/material/core';


// components
import {TextFieldInForm} from "@/angular-blocworx/components/fields/text/text-field-in-form.component";
import {TextFieldInData} from "@/angular-blocworx/components/fields/text/text-field-in-data.component";
import {
    TextFieldCreateEditRadioButton
} from "@/angular-blocworx/components/fields/text/text-field-create-edit-radio-button.component";
import {
    TextFieldCreateEditPopup
} from "@/angular-blocworx/components/fields/text/text-field-create-edit-popup.component";
import {
    ChangeBlocModule
} from "@/angular-blocworx/components/BlocSettings/changeBlocModule/change-bloc-module.component";
import {AutocompleteSearch} from "@/angular-blocworx/components/autocompleteSearch/autocomplete-search.component";
import {VersionControl} from "@/angular-blocworx/components/admin/version-control/version-control.component";
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {
    EvervaultAppSetting
} from "@/angular-blocworx/components/admin/app-setting/evervault/evervault-app-setting.component";
import {AppSettingService} from "@/angular-blocworx/components/admin/app-setting/app-setting.service";
import {ResultsBoxInForm} from "@/angular-blocworx/components/fields/results-box/results-box-in-form.component";
import {ResultsBoxInData} from "@/angular-blocworx/components/fields/results-box/results-box-in-data.component";
import {
    ResultsBoxCreateEditRadioButton
} from "@/angular-blocworx/components/fields/results-box/results-box-create-edit-radio-button.component";
import {
    ResultsBoxCreateEditPopup
} from "@/angular-blocworx/components/fields/results-box/results-box-create-edit-popup.component";

import {ManageAndBuildTemplatesComponent} from "@/angular-blocworx/components/BlocSettings/manage-and-build-templates/manage-and-build-templates.component";

import {DataEditHistory} from "@/angular-blocworx/components/scanStation/data-edit-history/data-edit-history.component";


// directives
import {NextOrPreviousFieldDirective} from '@/angular-blocworx/directives/app.nextorpreviousfield';
import {
    ProceedWithPushToLivePopupComponent
} from "@/angular-blocworx/components/admin/version-control/proceed-with-push-to-live-popup.component";

import {
    CalendarResourcePlanningInForm
} from "@/angular-blocworx/components/fields/calendar-resource-planning/calendar-resource-planning-in-form.component";
import {
    CalendarResourcePlanningInData
} from "@/angular-blocworx/components/fields/calendar-resource-planning/calendar-resource-planning-in-data.component";
import {
    CalendarResourcePlanningCreateEditPopup
} from "@/angular-blocworx/components/fields/calendar-resource-planning/calendar-resource-planning-create-edit-popup.component";
import {
    CalendarResourcePlanningEditRadioButton
} from "@/angular-blocworx/components/fields/calendar-resource-planning/calendar-resource-planning-edit-radio-button.component";
import {
    AddNewRecordComponent
} from "@/angular-blocworx/components/fields/calendar-resource-planning/add-new-record.component";
import {
    EditRecordComponent
} from "@/angular-blocworx/components/fields/calendar-resource-planning/edit-record.component";
import {
    ShowCountedDataPopup
} from "@/angular-blocworx/components/fields/calendar-resource-planning/show-counted-data-popup.component";
import {ScanStationComponent} from "@/angular-blocworx/components/scanStation/scan-station.component";


setAngularJSGlobal(angular);

@NgModule({
    declarations: [
        TextFieldInForm, TextFieldInData, TextFieldCreateEditRadioButton, TextFieldCreateEditPopup,
        CalendarResourcePlanningInForm, CalendarResourcePlanningInData, CalendarResourcePlanningCreateEditPopup, CalendarResourcePlanningEditRadioButton,
        ChangeBlocModule, AutocompleteSearch, NextOrPreviousFieldDirective, VersionControl, ProceedWithPushToLivePopupComponent, AddNewRecordComponent,
        EditRecordComponent, ShowCountedDataPopup, EvervaultAppSetting, ResultsBoxInForm, ResultsBoxInData, ResultsBoxCreateEditRadioButton, ResultsBoxCreateEditPopup,
        ScanStationComponent, ManageAndBuildTemplatesComponent, DataEditHistory
    ],
    imports: [
        BrowserModule, UpgradeModule, CommonModule, FormsModule, HttpClientModule, ReactiveFormsModule,
        MatAutocompleteModule,
        MatFormFieldModule,
        MatInputModule, BrowserAnimationsModule, MatDialogModule, MatButtonModule,
        MatIconModule, MatProgressSpinnerModule, MatSnackBarModule, RouterModule.forRoot([]),
        MatDatepickerModule, MatNativeDateModule, MatSelectModule, LayoutModule
    ],
    exports: [

        TextFieldInForm, TextFieldInData, TextFieldCreateEditRadioButton, TextFieldCreateEditPopup, ChangeBlocModule, AutocompleteSearch, VersionControl, EvervaultAppSetting,
        ResultsBoxInForm, ResultsBoxInData, ResultsBoxCreateEditRadioButton, ResultsBoxCreateEditPopup,
        ScanStationComponent, ManageAndBuildTemplatesComponent, DataEditHistory
    ],
    providers: [
        {provide: MAT_DATE_LOCALE, useValue: 'en-GB'},
        AppSettingService
    ]
})

export class AppModule implements DoBootstrap {
    constructor(@Inject(UpgradeModule) private upgrade: UpgradeModule) {
    }

    ngDoBootstrap() {
        this.upgrade.bootstrap(document.documentElement, ['app']);
    }
}
