import * as angular from 'angular';
import SignInWithGoogleController from './controllers/sign-in-with-google.controller';
import StandardComponent from 'components/standards/components/StandardComponent';

"use strict";

class SignInWithGoogleComponent extends StandardComponent {

    // This will enable the debug mode for this component
    public debug = false;
    public moduleFolder = 'components/sign-in-with-google';

    /**
     * Please make sure that each function contains correct
     * documentation.
     */

    public bindings = {
        main: '<',
    }

    // This will set whatwill be the controller of this component
    public controller: any = SignInWithGoogleController;

    // This will set an alias
    public controllerAs: string = 'google';
}

angular.module('SignInWithGoogleModule').component('signInWithGoogle', <StandardComponent> new SignInWithGoogleComponent);