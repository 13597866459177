import * as angular from 'angular';

"use strict";

angular.module('ScanStationModule')
    .controller('TableOrderController', TableOrderController);

// Injection of each class
TableOrderController.$inject = ['$scope', 'ScopeAndLocal', 'scanStation', 'tableOrderService'];

// Controller Constructor
function TableOrderController($scope, ScopeAndLocal, scanStation, tableOrderService)  {

    // Creating the view model reference
    let vm = this;
    vm.scanStationFields = undefined;
    vm.defaultOrderLimit = 5;

    vm.$onInit = async function () {
        try {

            ScopeAndLocal.startVariable(vm, $scope, 'scanStation');
            ScopeAndLocal.startVariable(vm, $scope, 'field');

            // loading th scan station fields from the previous loaded data on scanStationObj
            if (vm.scanStation?.scanStationObj?.scanStationFields != undefined) {
                vm.scanStationFields = vm.scanStation.scanStationObj.scanStationFields;
            }

        } catch (e) {
            console.log(e);
        }

    }

    /**
     * This will trigger an update to the object data:
     * scanStation.scanStationObj.stationDetails.field_results_limit
     *
     * @param field
     * @param fieldParameter
     * @param shouldBeNumber
     */
    vm.updateFieldResultLimit = async function (scanStationId, fieldResultsLimit) {

        // setting the scan station parameter on the backend
        await vm.scanStation.setScanStationParameter(scanStationId,'field_results_limit', fieldResultsLimit);

        // update of the scan station fields order table
        await vm.loadScanStationFieldsOrder(scanStationId)
    }

    /**
     * This should start the <table-order> action table-order.html.
     * @param scanStationId
     */
    vm.loadScanStationFieldsOrder = async (scanStationId) => {

        // this will set the order limit
        let scanStationFieldsOrderLimit = vm.scanStation?.scanStationObj?.stationDetails?.field_results_limit > 0
            ? vm.scanStation.scanStationObj.stationDetails.field_results_limit
            : vm.defaultOrderLimit;

        // this will load the scan station field to order
        vm.scanStation.fieldsOrder = await scanStation.getScanStationFieldsOrder(scanStationId, scanStationFieldsOrderLimit);

        $scope.$apply();
    }

    /**
     * This will change 2 fields:
     *
     * beforeField : this is the field that was selected before on the select field
     * changeField : this is the new refence of the element to change the order from the beforeField.
     *
     * @param beforeField
     */
    vm.changeFieldOrder = async (beforeField) => {

        // this if the field id to order
        let fieldIdToUpdateOrder = vm.scanStation.fieldIdToUpdateOrder[vm.field.id];

        // Checking if we have the field that we will be changing the order
        let changeField = await tableOrderService.loadFieldToChange(fieldIdToUpdateOrder, vm.scanStation.fieldsOrder)

        // validation if we can't find the field id
        if (!changeField) {
            throw new Error(`Field ${fieldIdToUpdateOrder} not found`);
        }

        // loading the previous field order
        let previousFieldOrder = changeField.field_order;

        // setting the new field order
        changeField.field_order = parseInt(beforeField?.field_order);

        // setting the order of the previousField
        beforeField.field_order = previousFieldOrder;

        // update data on those 2 fields orders
        await scanStation.updateScanStationObjectOrder(beforeField, changeField);

        // adding back the spliced field
        vm.scanStation.fieldsOrder.push(changeField)

        // sorting the clone object
        let scanStationFieldsOrder = vm.scanStation.fieldsOrder.sort((a, b) => a.field_order - b.field_order);
        scanStationFieldsOrder = scanStationFieldsOrder.slice(0, vm.scanStation.scanStationObj.stationDetails.field_results_limit);

        vm.scanStation.fieldsOrder = [];
        vm.scanStation.fieldsOrder = scanStationFieldsOrder;

        vm.scanStation.fieldIdToUpdateOrder = []
        vm.scanStation.fieldsOrder.forEach((field) => {
            vm.scanStation.fieldIdToUpdateOrder[field.id] = field.id;
        });
    }

}

export default TableOrderController;
