import * as angular from 'angular';
import * as $ from "jquery";

"use strict";

angular.module('BlocworxModule')
       .controller('ScanStationController', ScanStationController);

ScanStationController.$inject = ['$scope', 'AlertFactory','ScopeAndLocal',
    '$mdDialog', 'LanguagesFactory'];

// Controller Constructor
function ScanStationController($scope, AlertFactory, ScopeAndLocal,
    $mdDialog, LanguagesFactory) {

    // Creating the view model reference
    let vm = this;
    vm.alertFactory = null;
    vm.languages = null;
    vm.alertOpen = false;
    vm.classToSet = undefined;
    vm.confirmDeletion = undefined;

    /**
     * Constructor class.
     */
    vm.$onInit = function () {
        try
        {
            vm.alertFactory = AlertFactory;
            vm.alertFactory.status = false;
            vm.languages = LanguagesFactory;
            ScopeAndLocal.startVariable(vm, $scope, 'scanStation');
            ScopeAndLocal.startVariable(vm, $scope, 'main');
            ScopeAndLocal.startVariable(vm, $scope, 'result');
            ScopeAndLocal.startVariable(vm, $scope, 'userRoles');
            ScopeAndLocal.startVariable(vm, $scope, 'lc');
            ScopeAndLocal.startVariable(vm, $scope, 'data');

        } catch (e) {
            console.log(e);
        }
    }

    /**
     * Method that will be doing the show alert on
     * the frontend.
     *
     * @param $event
     */
    vm.showAlert = ($event) => {

        // set of the class for the #modal get it from the body class
        vm.classToSet = $('body').hasClass('dark') ? 'dark' : 'light';

        // Set of the class so we can have a modal with the current theme
        $('#modal').removeClass().addClass(vm.classToSet).show();

        // Showing the alert dialog
        $mdDialog.show({
            controller: ScanStationController,
            controllerAs: 'ctrl',
            templateUrl: 'components/blocworx/components/scanStation/template/alert.html',
            parent: angular.element(document.querySelector('#modal')),
            targetEvent: $event,
            clickOutsideToClose: () => {
                $('#modal').removeClass(vm.classToSet).hide();
                return true
            },
            scope: $scope,
            preserveScope: true,
        });

    }

    /**
     * This method will hide the actual alert that is open.
     * @param event
     */
    vm.hideAlert = () => {
        $('#modal').removeClass(vm.classToSet).hide();
        $mdDialog.cancel();
    }

    /**
     * This will show the delete all data button.
     */
    vm.showDeleteAllDataButton = () => {
        vm.alertFactory.status = true;
    }

    /**
     * This will update data from a ScanStation
     * @param entryID
     * @param newData
     * @param stationID
     */
    vm.updateData = async function (entryID, newData, stationID) {
        try
        {
            await vm.data.updateData(entryID, newData, stationID, vm.hideAlert);
            $scope.$apply();
        } catch (e) {
            console.log(e);
        }

    }

    /**
     * This will delete data for scan station data to delete
     * by sending the entry ID.
     *
     * @param entryID
     */
    vm.deleteData = async function (entryID) {
        try
        {
            await vm.data.deleteData(entryID, vm.hideAlert);
            $('#lookup-submit-button').trigger('click');

            $scope.$apply();
        } catch (e) {
            console.log(e);
        }
    }

    /**
     * This will delete all data function on scan station.
     * @param scanStation
     */
    vm.deleteAllData = async function (scanStation) {
        try
        {
            await vm.alertFactory.deleteAllData(scanStation)
            await vm.alertFactory.closeWindow();
            $scope.$apply();
        } catch (e) {
            console.log(e);
        }
    }
}

export default ScanStationController;