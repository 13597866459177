import * as angular from 'angular';

'use strict';

angular.module('BlocworxModule')
       .factory('SCS', ScanStationService);

ScanStationService.$inject = ['$http', 'Configuration'];

/**
 * Object that will create a Icon Factory, all methods of factory
 * will be available from outside.
 *
 * @param $http
 * @returns {ScanStationService}
 * @constructor
 */
function ScanStationService($http, Configuration) {

    let ScanStationService = this;
    let apiUrl = Configuration.getApiUrl();
    let defaultLogoPath = '/images/customer-logos/default-logo-instruction.png';

    /**
     * Constructor method.
     */
    ScanStationService.$onInit = () => {
        try
        {

            // at the start of this we are setting the default logo
            ScanStationService.logo = defaultLogoPath;

        } catch (exception) {
            console.log(exception);
        }
    }

    /**
     * This function will be responsible to ask the PHP backend side
     * to check if exist a file.
     *
     * @param path
     */
    ScanStationService.existLogo = async (path) => {

        let apiBase = apiUrl + 'exist-file';
        let config = {
            params: {
                path : path
            }
        };
        let exist = await $http.get(apiBase, config);

        return exist.data;
    }


    /**
     * This function will be responsible to ask the PHP backend side
     * to check if exist a file.
     *
     * @param path
     * @param subDomain
     */
    ScanStationService.loadLogo = async (path, subDomain) => {

        let existLogo = await ScanStationService.existLogo(path);

        if(existLogo){
            ScanStationService.logo = `/api/customer-logos/${subDomain}.png`;
        }

        return ScanStationService.logo

    }

    /**
     * This will be responsible to search for a field that starts with
     * that searched text.
     *
     * @param path
     */
    ScanStationService.searchByFieldName = async (fieldNameToSearch) => {

        let apiBase = apiUrl + 'search-field-by-name';
        let config = {
            params: {
                fieldNameToSearch : fieldNameToSearch
            }
        };
        let exist = await $http.get(apiBase, config);

        return exist.data;
    }

    /**
     * This method will make sure that an automatic test can understand what is a field
     * to click, hover, type, by using a pattern field-slug-{whatever was passed}, and also
     * if something went bad, the default name is .field-slug-default.
     *
     * @param baseName
     */
    ScanStationService.getUniqueClassForAutomaticTests = (baseName) => {

        let automaticTestSlug = "field-slug-"

        // check if we have some value
        if (baseName == null) {
            return `${automaticTestSlug}default`
        }

        // step 1 : transform the field name to lower case and trim the value
        let preparedSlug = baseName.toLowerCase().trim();

        // step 2 : replace space for _
        preparedSlug = preparedSlug.replace(/\s/g, '_');

        automaticTestSlug+=preparedSlug;

        return automaticTestSlug;
    }

    // this will make sure that will be the constructor class like a controller one
    ScanStationService.$onInit();

    return ScanStationService;
}

export default ScanStationService;