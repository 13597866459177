import * as angular from 'angular';

"use strict";

angular.module('ScanStationModule')
       .service('tableOrderService', TableOrderService);

TableOrderService.$inject = ['$http', 'Configuration'];
/**
 * Service constructor.
 * @constructor
 */
function TableOrderService($http, Configuration) {

    let service = this;
    let apiUrl = Configuration.getApiUrl();

    /**
     * Previous way to order the fields, i am letting i here just if is
     * needed to use it again, being on history.
     * @param allFields
     * @param fieldIdToUpdateOrder
     * @param newOrderValue
     */
    service.orderFields = (allFields, fieldIdToUpdateOrder, newOrderValue) => {

        // getting the index of the field with specific id
        let index = allFields.findIndex((field) => field.id === fieldIdToUpdateOrder);

        // if we can find an element to move
        if (index !== -1) {

            // Get the object to be moved
            let selectedField = allFields[index];

            // Remove the selected object from the array
            allFields.splice(index, 1);

            // Adjust the field_order values for the remaining objects
            allFields.forEach((field) => {
                if (field.field_order >= newOrderValue) {
                    field.field_order += 1;
                }
            });

            // Update the field_order for the selected object and reinsert it
            selectedField.field_order = newOrderValue;
            allFields.splice(newOrderValue - 1, 0, selectedField);
        }

        return allFields;
    }

    /**
     * This will return the field order with minimum amount of
     * data to show on scan station edit.
     *
     * @param fieldId
     */
    service.getFieldOrder = async (fieldId) => {

        let fieldOrder = {};
        let apiBase = apiUrl + 'get-field-order';
        let config = {
            params: {
                fieldId: fieldId,
            }
        };

        let scanStationData = await $http.get(apiBase, config);

        // if we can find data, we must add tot eh scanStationFieldsOrder array
        if(scanStationData.data != undefined &&  scanStationData.data.fieldOrder != undefined) {
            fieldOrder = scanStationData.data.fieldOrder;
        }

        return fieldOrder
    }

    /**
     * This will load a field to change data, if can't find it
     * on the main list of vm.scanStation.fieldsOrder.
     *
     * @param fieldId
     * @param fieldsOrder
     */
    service.loadFieldToChange = async (fieldId, fieldsOrder) => {

        // first we try to load by the fieldsOrcer
        let changeField = fieldsOrder.find(field => field.id === fieldId);

        // if we can't find in the fieldsOrder means that we will need to load by backend query
        changeField = !changeField
                    ? await service.getFieldOrder(fieldId)
                    : fieldsOrder.splice(fieldsOrder.indexOf(changeField), 1)[0];

        return changeField;

    }
}